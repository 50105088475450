/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author danielCastano
 * @file Unauthorized/index.js
 * @description Unauthorized container
 */

/** Dependencies */
import React, { useEffect, useState } from "react";

import Sad from "../img/logoPrestapolisSolo.png";
import "./styles.css";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { Button } from "@material-ui/core";
import { useHistory, useParams, useLocation } from "react-router-dom";


const Used = ({nombres}) => {

  const goToHome = () => {
  
    setTimeout(() => {
      window.location.replace("https://www.prestapolis.com/");
    });
  };

  const nombre = nombres.split(" ", 1);

  return( 
        <div className="marginReject">
        <div className="logoSad">
          <div className="logoCentralSad">
          <img src={Sad} />{" "}
          </div>
        </div>
        <div className="textInicio">
          <br></br>
          <strong>Hola {nombre}</strong>
        </div>
        <br></br>
        <div className="textContenido">
          <p>Ya existe una solicitud previa realizada <strong>HOY</strong> en este <strong>COMERCIO</strong>, consulta con tu asesor de ventas para que valide el estado y puedas continuar el proceso de solicitud de crédito para la compra del producto que tanto deseas.</p>
        </div>
        <div className="textInicio" style={{marginBottom: "20pt"}}>
          <br></br>
          <strong>¡Te esperamos pronto en Prestápolis!</strong>
        </div>
        <br></br>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="btn-atras"
          style={{ marginBottom: "20pt" }}
          onClick={(e) => goToHome()}
        >
          <strong>
            Finalizar
          </strong>
        </Button>
        </div>
  )};

export default Used;