import React from "react";
import "../created/CreatedStyles.css"
import {TextField, Modal, Button} from "@material-ui/core";

const ModalPage = ({isOpen, openModal, message, seconMessage, data, eventButton}) => (
	<div className="modalDiv">			
	<Modal
		open={isOpen}
		onClose={openModal}	
						
		>
			<div className="modal">
				<div className="div-1">
					<h3>{message}</h3>
				</div>
				<div>
					<h6>{seconMessage}</h6>
				</div>
			</div>
	</Modal>
	</div>
);
	
export default ModalPage;