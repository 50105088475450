import { Hidden, makeStyles } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { set, useForm } from "react-hook-form";
import ReactLoading from "react-loading";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Alert from "@material-ui/lab/Alert";

import notificacion from "../img/Notification.svg";
import mail from "../img/Mail.svg";
import mobil from "../img/mobil.svg";
import document from "../img/doc_frontal_val.svg"

import "./CreatedStyles.css";
import { Typography } from "@material-ui/core";
import { Button, Modal } from "@material-ui/core";
import { HelpValidate } from "../../../../domain/help/HelpValidate";

import {
  generateReject,
  getValidate,
  generateRejectV2,
  getInfoByToken
} from "../../../../infractructura/ApiPrestapolisPublic";

import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    display: "flex",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: "primary",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 290,
  },

  margin: {
    margin: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(1),
  },
  centerv: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
  },
  borderRadius: {
    borderRadius: "3%",
  },
}));

export default function PersonDataSection({
  handleData,
  handleNext,
  personData,
  handlePrev,

  commerceData,
  planSelect,
  request,
  handleReject,

  setIdSolicitud,
  setPersona,
  setContact,
  setNombres,
  setPrimerApellido,
  setSegundoApellido,
  setFechaNacimiento,
  setFechaExpedicion,
  setCelular,
  setCorreo,

  // token
  token,
  setsucursalId,
  sucursalId,
  idComerce,
  idVendedor,
  setidVendedor,

  persona,
  contact,
  nombres,
  primerApellido,
  segundoApellido,
  fechaNacimineto,
  fechaExpedicion,
  celular,
  correo,

  primerCredito,
  morasVigentes,
  idSolicitud,
  handleUltimateStep,
  handelMessageGeneric,

  setCodBandera,
  codBandera,
  capacidadMedia,
  setCapacidadMedia,
  capacidadMinima,
  setCapacidadMinima,

  valorMonto,
  valorinicial,

  handleUsed,
  setPassDirect,
}) {

  const history = useHistory();
  const { code } = useParams();
  const classes = useStyles();
  const {
    register,
    setError,
    clearErrors,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm();

  const [showLoad, setshowLoad] = useState(false);

  const [isContinuo, setIsContinuo] = useState(false);
  const [isErrorContinue, setIsErrorContinue] = useState(false);

  const urlPolicy = "https://www.prestapolis.com/politicas-de-privacidad";

  const [visible, setVisible] = useState(false);

  const [checked, setChecked] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [presionBoton, setPresionBoton] = useState(false);

  const [checkedCellphone, setCheckedCellphone] = useState(false);
  const [checkedEmail, setCheckedEmail] = useState(false);
  const [checkDocument, setCheckDocument] = useState(false);
  const[baas, setBaas] = useState(commerceData.baas != true ? false : true);

  const [acceptDocument, setAcceptDocument] = useState(false);
  const [acceptCell, setAcceptCell] = useState(false);
  const [nextWindow, setNextWindow] = useState(false);

  const [confirm, setConfirm] = useState("Confirmar")
  const [documentId, setDocumentID] = useState("");

  const [validateYearNaci, setValidateYearNaci] = useState(null);
  const [validateYearExpe, setValidateYearExpe] = useState(null);

  // Data sucursal and vendeor by token
  const [vendedorToken, setvendedorToken] = useState("")

  const onDismiss = () => {
    setVisible(!visible);
  };

  const onSubmit = async (data) => {

    setshowLoad(true);
    let valueRequiredAmount = request.requests[0].valueRequiredAmount;
    if (typeof valueRequiredAmount === "string") {
      valueRequiredAmount = valueRequiredAmount.includes(".")
        ? valueRequiredAmount.split(".")
        : valueRequiredAmount.split(",");
      valueRequiredAmount = valueRequiredAmount.join("");
      valueRequiredAmount = parseInt(valueRequiredAmount, 10);
    }

    let abono = request.requests[0].abonoInicial;
    if (typeof abono === "string") {
      abono = abono.includes(".") ? abono.split(".") : abono.split(",");
      abono = abono.join("");
      abono = parseInt(abono, 10);
    }

    let doc = idNumber;
    if (typeof doc === "string") {
      doc = doc.includes(".") ? doc.split(".") : doc.split(",");
      doc = doc.join("");
      doc = parseInt(doc, 10);
    }

    let idTipo = abono === 0 ? 5 : 1;
    let newComercio =  commerceData.apikey == process.env.REACT_APP_KEY_COMERCE ? process.env.REACT_APP_ID_INTERES : request.requests[0].interes.id
    
    let dataRequest = {
      producto: request.requests[0].descripcion == "" ? "Libre inversion" : request.requests[0].descripcion,
      
      interes: {
        id: newComercio,
      },
      valormontorequerido: parseInt(valueRequiredAmount, 10),
      aceptopolitica: data.acceptPolitics,
      autorizoconsulta: data.authorizeConsultation,
      plazosolicitado: request.requests[0].loanTerm,
      costoPlataforma: request.requests[0].costoPlataforma,
      datosPersona: {
        birthDate: fechaNacimineto,
        issuerDate: fechaExpedicion,
        documentNumber: data.idNumber,
        documentType: data.idType == "" ? "CC" : "CC",
        nombrecompleto: `${nombres} ${primerApellido} ${segundoApellido}`,
        firstLastName: primerApellido,
        firstName: nombres,
        secondLastName: segundoApellido,
        primer_credito: primerCredito,
        moras_vigentes: morasVigentes,
        contactos: [
          {
            cellphone: celular,
            email: correo,
          },
        ],
      },
      tipoSolicitud: {
        id: idTipo,
      },
      sucursal: {
        id: token || sucursalId? sucursalId : 1,
      },
      comercio: {
        id: idComerce,
      },
      abono: abono,
      vendedor: {
        id: token ? idVendedor : 1,
      },
      comision: planSelect.commission,
      porcentajeVsf:commerceData.porcentaje_vsf,
      vsf:request.requests[0].vsf, 
      seguro:0, 
      id: idSolicitud,
    };


    await generateRejectV2(dataRequest)
      .then((response) => {
        if (response.respuesta && response.codigoRespuesta === "0000" && response.respuesta.bandera.code) {
          setCodBandera(response.respuesta.bandera.code);
        }
        if (response.respuesta && response.codigoRespuesta === "0000" && response.respuesta.bandera.code === "003" || response.respuesta && response.codigoRespuesta === "0000" && response.respuesta.bandera.code === "004") {
          handleData(data);
          handleUltimateStep();
          setshowLoad(true);
          setPassDirect(true);
          //setCodBandera(response.respuesta.bandera.code);
          if (response.respuesta && response.respuesta.solicitud) {
            setIdSolicitud(response.respuesta.solicitud.id);
          }
        }
        if (response.respuesta && response.codigoRespuesta === "0000" && response.respuesta.bandera.code === "001" || response.respuesta && response.codigoRespuesta === "0000" && response.respuesta.bandera.code === "002") {
          handleData(data);
          handleReject();
          setshowLoad(true);
        }
        if (response.codigoRespuesta === "0000" && response.respuesta && response.respuesta.bandera.code != "003" && response.respuesta.bandera.code != "004" && response.respuesta.bandera.code != "002" && response.respuesta.bandera.code != "001"
          || response.codigoRespuesta === "RQ003" || response.codigoRespuesta === "FL002" || response.respuesta === false || response.codigoRespuesta === "0000" && response.respuesta.bandera.code === "003" && capacidadMinima < 85000 ||
          response.codigoRespuesta === "0000" && response.respuesta.bandera.code === "004" && capacidadMedia < 85000) {
          handleData(data);
          handleUltimateStep();
          setshowLoad(true);
          setPassDirect(true);
          if (response.respuesta && response.respuesta.solicitud) {
            setIdSolicitud(response.respuesta.solicitud.id);
          }
        }
        if (response.codigoRespuesta === "0001" && response.respuesta || response.codigoRespuesta === "0002" && response.respuesta || response.codigoRespuesta === "0003" && response.respuesta) {
          handleData(data);
          handleUsed();
          setshowLoad(true);
        }

        if (response.codigoRespuesta === "002") {
          handelMessageGeneric()
        }

        if (response.codigoRespuesta === "003") {
          handelMessageGeneric()
        }
      })
  };

  const backStep = (e) => {
    handlePrev();
  };

  const documentNumber = useRef();
  const issuerDate = useRef();

  const cellPhone = useRef();
  const emailNew = useRef();

  const validate = (e) => {
    //getValidate(documentNumber.current.value , issuerDate.current.value);
    setPresionBoton(true);
  };

  useEffect(async () => {

    // Validar si hay token para setear los datos usando servicio
    if (token) {
      const dataSolicitud = await getInfoByToken(token)
      
      if (dataSolicitud.codigoRespuesta === "0000") {

        setValue('idNumber', dataSolicitud.respuesta.datosPersona.documentNumber);
        setPrimerApellido(dataSolicitud.respuesta.datosPersona.firstLastName)
        setCelular(dataSolicitud.respuesta.datosPersona.contactos[0].cellphone)
        setCorreo(dataSolicitud.respuesta.datosPersona.contactos[0].email)
        setFechaExpedicion(dataSolicitud.respuesta.datosPersona.issuerDate)
        setidVendedor(dataSolicitud.respuesta.vendedor.id)
        setsucursalId(dataSolicitud.respuesta.sucursal.id)

      }
    }


    setCapacidadMinima(valorMonto - valorinicial - 300000)
    setCapacidadMedia(valorMonto - valorinicial - 400000)
    if (presionBoton) {
      await getValidate(documentNumber.current.value, issuerDate.current.value)
        .then((response) => {
      
          if (response.respuesta) {
            setPersona(response.respuesta);
            setNombres(response.respuesta.firstName);
            setPrimerApellido(response.respuesta.firstLastName);
            setSegundoApellido(response.respuesta.secondLastName);
            setFechaNacimiento(response.respuesta.birthDate);
            setFechaExpedicion(response.respuesta.issuerDate);
          }

          if (response.respuesta && response.respuesta.contactos && response.respuesta.contactos.length) {
            setContact(response.respuesta.contactos[0]);
            setCelular(response.respuesta.contactos[0].cellphone);
            setCorreo(response.respuesta.contactos[0].email);
            setCorreo(response.respuesta.datosPersona[0].fechaExpedicion);
          }
          if (response.codigoRespuesta === "VD001") {
            setIsOpenModal(true);
          }
        })
        .catch((err) => {
        
        });
      setPresionBoton(false);
    }

  }, [presionBoton, setPresionBoton, setCapacidadMinima, setCapacidadMedia]);

  const idNumber = register("idNumber", {
    required: "La cedula es requerida es requerido.",
  });

  const handleChange = (e) => {
    setNombres(e.target.value);
  };

  const handleChange2 = (e) => {
    setPrimerApellido(e.target.value);
  };

  const handleChange3 = (e) => {
    setSegundoApellido(e.target.value);
  };

  const handleChange4 = (e) => {
    setFechaNacimiento(e.target.value);
  };

  const handleChange5 = (e) => {
    setCelular(e.target.value);
  };

  const handleChange6 = (e) => {
    setCorreo(e.target.value);
  };

  const handleChange7 = (e) => {
    setFechaExpedicion(issuerDate.current.value);
  };

  const handleClose = () => {
    setIsOpenModal(false);
  };

  const handleNextDocument = data => {
    setDocumentID(documentNumber.current.value);
    if (acceptDocument === false) {
      setCheckDocument(true);
    }
    else if (acceptCell === false) {
      handleNextCell();
    } else {
      handleNextEmail();
    }
    if (nextWindow === true) {
      onSubmit(data);
    }
  }

  const handleCloseDocument = () => {
    setCheckDocument(false);
  }

  const handleNextCell = () => {
    setCheckDocument(false);
    setCheckedCellphone(true);
    setAcceptDocument(true);
  }

  const handleCloseCell = () => {
    setCheckedCellphone(false);
  }

  const handleNextEmail = () => {
    setCheckedCellphone(false);
    setAcceptCell(true);
    setCheckedEmail(true);
  }

  const handleCloseEmail = () => {
    setCheckedEmail(false);
  }

  const handleNextWindow = () => {
    setCheckedEmail(false);
    setConfirm("Continuar");
    setNextWindow(true);
  }

  const yearExpedicion = parseInt(fechaExpedicion.split("-", 1));

  const yearNacimiento = parseInt(fechaNacimineto.split("-", 1));

  const fecha = new Date();
  const añoActual = fecha.getFullYear();

  useEffect(() => {
    if (yearExpedicion <= yearNacimiento + 17 || yearExpedicion > añoActual) {
      setValidateYearExpe(true);
    } else {
      setValidateYearExpe(false);
    }

    if (yearExpedicion <= yearNacimiento + 17 || añoActual - yearNacimiento > 80) {
      setValidateYearNaci(true);
    } else {
      setValidateYearNaci(false);
    }
  }, [yearExpedicion, yearNacimiento, añoActual]);

  if (showLoad) {
    return (
      <>
        <ReactLoading
          type={"spokes"}
          color={"#000"}
          height={50}
          width={50}
          className="loading-creat"
        />
        <div style={{ marginLeft: "10pt" }}>Estamos consultado centrales de información, pronto te daremos respuesta.</div>
      </>
    )
  }
  else {
    return (
      <>
        {presionBoton ?
          (
            <>
              <Modal
                open={presionBoton}
              >
                <div className="modal">
                  <ReactLoading
                    type={"spokes"}
                    color={"#000"}
                    height={50}
                    width={50}
                    className="loading-creat2"
                  />
                  <div className="loadingTextPerson"><strong>Esperanos mientras validamos la información ingresada...</strong></div>
                </div>
              </Modal>
            </>
          ) : <Modal
            open={isOpenModal}
          >
            <div className="modalNotificaion" style={{ textAlign: "center" }}>
              <div>
                <img
                  className="logoNotificacion"
                  alt="Logo"
                  id="industriaycomerio"
                  src={notificacion}
                />
                <h3 className="titleNotificacion">Validación de Información</h3>
                <h6 className="textNotificacion">No hemos encontrado información respecto a los datos suministrados, por esta razón le pedimos que llene los campos vacíos.</h6>
              </div>
              <Button
                variant="contained"
                color="primary"
                className="button-modal"
                onClick={(e) => handleClose()}
              >Aceptar</Button>
            </div>
          </Modal>
        }

        <div className="confirm">
          <section className="section">
            <form onSubmit={handleSubmit(handleNextDocument)} autocomplete="off">

              <Modal
                open={checkDocument}
              >
                <div className="modalDocument" style={{ textAlign: "center" }}>
                  <div>
                    <img
                      className="logoDocument"
                      alt="Logo"
                      id="industriaycomerio"
                      src={document}
                    />
                    <h3 className="titleDocument" style={{ marginBottom: "-20pt", fontSize: "16pt" }}>¿El número de documento {<span style={{ fontWeight: "bold" }}>{documentId}</span>} es correcto?</h3>
                    <br></br>
                  </div>
                  <div className={classes.wrapper}>
                    <Grid container justify="center" style={{ marginTop: "20px" }}>
                      <Button
                        variant="contained"
                        
                        style={{ 
                          textTransform: "none", 
                          width: "14rem",
                           borderRadius: "100px", 
                           marginRight: "2pt" ,
                           color:'#FFFFFF',
                           background:'#2BAEB3'}}
                        onClick={handleNextCell}
                       
                        
                      >
                        <strong>
                          Confirmar
                        </strong>
                      </Button>
                    </Grid>
                    {isContinuo && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn-editar"
                    onClick={(e) => handleCloseDocument()}
                  >Editar</Button>
                </div>
              </Modal>

              <Modal
                open={checkedCellphone}
              >
                <div className="modalCellphone" style={{ textAlign: "center" }}>
                  <div>
                    <img
                      className="logoCellphone"
                      alt="Logo"
                      id="industriaycomerio"
                      src={mobil}
                    />
                    <h3 className="titleCellphone" style={{ marginBottom: "-20pt" }}>¿El número celular {<span style={{ fontWeight: "bold" }}>{celular}</span>} es correcto?</h3>
                    <br></br>
                  </div>
                  <div className={classes.wrapper}>
                    <Grid container justify="center" style={{ marginTop: "20px" }}>
                      <Button
                        variant="contained"
                       
                        style={{ 
                          textTransform: "none",
                           width: "14rem", 
                           borderRadius: "100px", 
                           marginRight: "2pt",
                           color:'#FFFFFF',
                           background:'#2BAEB3' }}
                        onClick={handleNextEmail}
                        className="MuiTypography-colorPrimary"
                      >
                        <strong>
                          Confirmar
                        </strong>
                      </Button>
                    </Grid>
                    {isContinuo && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn-editar"
                    onClick={(e) => handleCloseCell()}
                  >Editar</Button>
                </div>
              </Modal>

              <Modal
                open={checkedEmail}
              >
                <div className="modalEmail" style={{ textAlign: "center" }}>
                  <div>
                    <img
                      className="logoNotificacion"
                      alt="Logo"
                      id="industriaycomerio"
                      src={mail}
                    />
                    <h3 className="titleEmail" style={{ marginBottom: "-10pt" }}>¿El correo {<span style={{ fontWeight: "bold" }}>{correo}</span>} es correcto?</h3>
                    <br></br>
                  </div>
                  <div className={classes.wrapper}>
                    <Grid container justify="center" style={{ marginTop: "20px" }}>
                      <Button
                        variant="contained"
                        
                        style={{ 
                          textTransform: "none",
                           width: "14rem",
                            borderRadius: "100px",
                             marginRight: "2pt",
                             color:'#FFFFFF',
                             background:'#2BAEB3' }}
                        onClick={handleNextWindow}
                        className="MuiTypography-colorPrimary"
                      >
                        <strong>
                          Confirmar
                        </strong>
                      </Button>
                    </Grid>
                    {isContinuo && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn-editar"
                    onClick={(e) => handleCloseEmail()}
                  >Editar</Button>
                </div>
              </Modal>

              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                className="input-person"
              >
                <Grid item md={true} sm={true} xs={true} x>
                  <TextField
                    placeholder="1010872234"
                    label="Número de cédula"
                    fullWidth
                    color="primary"
                    id="input-select"
                    name="idNumber"
                    type="tel"
                    autocomplete="off"
                    inputRef={documentNumber}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      className: "label__color",
                    }}
                    defaultValue={personData.idNumber}
                    {...HelpValidate().limitLengthNumber(
                      register("idNumber", {
                        required: "La cedula es requerida.",
                      }),
                      6,
                      10,
                      setError,
                      clearErrors,
                    )}
                    required={"La cedula es requerida."}
                    error={!!errors.idNumber}
                    helperText={errors.idNumber?.message}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                className="input-person"
                id="input-person"
              >
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                    fullWidth
                    label="Fecha de Expedición"
                    type="date"
                    autocomplete="off"
                    defaultValue="aaaa-mm-dd"
                    value={fechaExpedicion}
                    inputRef={issuerDate}
                    onChange={handleChange7}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      className: "label__color",
                    }}
                    name="issuerDate"
                    error={parseInt(fechaExpedicion.split("-", 1)) <= parseInt(fechaNacimineto.split("-", 1)) || yearExpedicion > añoActual}
                    helperText={yearExpedicion > añoActual ? "La fecha de expedición no puede ser mayor a la fecha actual" : null ||
                      parseInt(fechaExpedicion.split("-", 1)) <= parseInt(fechaNacimineto.split("-", 1)) ? "La fecha de expedición no puede ser menor o igual a la fecha de nacimiento" : null}
                  />
                </Grid>
              </Grid>

              {/*<Button
              variant="contained"
              color="primary"
              className="btn-validacion"
              style={{ marginBottom: "20pt" }}
              onClick={(e) => validate()}
            >
              <strong>
                Validación
              </strong>
            </Button>*/}

              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                className="input-person"
              >
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                    placeholder="Nombres"
                    label="Nombres"
                    fullWidth
                    name="names"
                    disabled={persona.firstName || presionBoton}
                    id="input-select"
                    type="text"
                    autocomplete="off"
                    value={nombres}
                    onChange={handleChange}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      className: "label__color",
                    }}
                    required={"El Nombre es requerido."}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                className="input-person"
              >

                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                    placeholder="Primer apellido"
                    label="Primer apellido"
                    fullWidth
                    id="input-select"
                    name="firstSurname"
                    disabled={persona.firstLastName || presionBoton}
                    autocomplete="off"
                    value={primerApellido}
                    onChange={handleChange2}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      className: "label__color",
                    }}
                    type="text"
                    required={"El Primer Apellido es requerido."}
                    error={!!errors.firstLastName}
                    helperText={errors.firstLastName?.message}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                className="input-person"
              >
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                    placeholder="Segundo apellido"
                    label="Segundo apellido"
                    fullWidth
                    id="input-select"
                    name="secondSurname"
                    disabled={persona.secondLastName || presionBoton}
                    autocomplete="off"
                    value={segundoApellido}
                    onChange={handleChange3}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      className: "label__color",
                    }}
                    type="text"
                    error={!!errors.secondLastName}
                    helperText={errors.secondLastName?.message}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                className="input-person"
                id="input-person"
              >
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                    fullWidth
                    label="Fecha de Nacimiento"
                    type="date"
                    autocomplete="off"
                    defaultValue="aaaa-mm-dd"
                    disabled={presionBoton}
                    value={fechaNacimineto}
                    onChange={handleChange4}
                    required
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      className: "label__color",
                    }}
                    error={parseInt(fechaExpedicion.split("-", 1)) <= parseInt(fechaNacimineto.split("-", 1)) + 17 || añoActual - yearNacimiento > 80}
                    helperText={parseInt(fechaExpedicion.split("-", 1)) <= parseInt(fechaNacimineto.split("-", 1)) + 17 ? "La diferencia entre la fecha de expedición y la fecha de nacimiento debe ser mayor de 18 años" : null
                      || añoActual - yearNacimiento > 80 ? "No se puede realizar el crédito si la persona tiene más de 80 años" : null}
                  />
                </Grid>
              </Grid>

              
              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                className="input-person"
              >
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                    placeholder={baas == true ? '3004746363' : '3116952930'}
                    label="Celular"
                    fullWidth
                    id="input-select"
                    name="cellphone"
                    autocomplete="off"
                    inputRef={cellPhone}
                    disabled={presionBoton}
                    type="number"
                    value={celular.replace(/\D/g, "").trim()}
                    onChange={handleChange5}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      className: "label__color",
                    }}
                    inputProps={{ maxLength: 10, minLength: 10 }}
                    required={"El celular es requerido."}
                    error={celular.length < 10 && celular.length !== 0 || celular.length > 10}
                    helperText={celular.length < 10 && celular.length !== 0 ? "El celular no puede tener menos de 10 numeros" : null
                      || celular.length > 10 ? "El celular no puede tener mayor de 10 numeros" : null}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                className="input-person"
              >
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                    placeholder={baas == true ?  'example@gmail.com' : 'info@prestapolis.com'}
                    label="Correo electrónico"
                    fullWidth
                    id="input-select"
                    name="email"
                    type="email"
                    autocomplete="off"
                    disabled={presionBoton}
                    inputRef={emailNew}
                    value={correo}
                    onChange={handleChange6}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      className: "label__color",
                    }}
                    required={"El email es requerido."}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} spacing={1} alignItems="flex-end">
                <Grid item xs={10}>
                  <Typography style={{color:'#2BAEB3'}} align="Left" id="typography">
                    Acepto&nbsp;
                    <Link
                      href="https://www.prestapolis.com/politicas-de-privacidad"
                      target="_blank"
                      underline="hover"
                      
                    >
                      políticas y términos
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Checkbox
                    required
                    name="acceptPolitics"
                    fullWidth
                    defaultChecked={personData.acceptPolitics}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    {...register("acceptPolitics", {
                      required: "Debe aceptar las politicas y terminas.",
                      pattern: {
                        minLength: 10,
                        message:
                          "Debe seleccionar el cuadro",
                      },
                    })}
                    error={!personData.acceptPolitics}
                    helperText={personData.acceptPolitics?.message}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} spacing={3} alignItems="center">
                <Grid item xs={10}>
                  <Typography color="primary" align="Left" id="typography2">
                    Autorizo consulta y reporte a centrales de bancos de datos e
                    información comercial
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Checkbox
                    required
                    name="authorizeConsultation"
                    fullWidth
                    defaultChecked={personData.authorizeConsultation}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    {...register("authorizeConsultation", {
                      required: "Debe autorizar la consulta y reporte.",
                      pattern: {
                        minLength: 10,
                        message:
                          "Debe seleccionar el cuadro",
                      },
                    })}
                    error={!!errors.authorizeConsultation}
                    helperText={errors.authorizeConsultation?.message}
                  />
                </Grid>
              </Grid>

              <div className={classes.wrapper}>
                <Grid container justify="center" style={{ marginTop: "20px" }}>
                  <Button
                    disabled={validateYearExpe || validateYearNaci}
                    type="submit"
                    variant="contained"
                    
                    style={{ 
                      textTransform: "none",
                       width: "14rem", 
                       borderRadius: "100px",
                       color:'#FFFFFF',
                       background:'#2BAEB3' }}
                   
                  >
                    <strong>
                      {confirm}
                    </strong>
                  </Button>
                </Grid>
                {isContinuo && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>

              <div className={classes.wrapper}>
                <Grid container justify="center" style={{ marginTop: "20px" }}>
                  <Button
                    //disabled={isContinuo}
                    //type="submit"
                    variant="contained"
                    color="primary"
                    onClick={(e) => backStep()}
                    className="btn-atras"
                  >
                    <strong>
                      Volver
                    </strong>
                  </Button>
                </Grid>
              </div>
              {isErrorContinue && (
                <Alert severity="error">
                  Usuario o Contraseña no son Validos
                </Alert>
              )}
            </form>
          </section>
        </div>
      </>
    )
  };
}
