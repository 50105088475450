import React from 'react';
import Solicitud from './public/component/view/solicitud/created';
import Calculadora from './public/component/view/simulator'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PersonDataSection from './public/component/view/solicitud/created'
import inicio from './public/component/view/inicio';
import "./prestapolis.css"
import ValidationPage from './public/component/view/solicitud/Validacion';

function PrestapolisPublic({ }) {

    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={`/validation/:code`}
                    component={ValidationPage}
                />

                <Route
                    exact
                    path={`/:code/token=:token`}
                    component={PersonDataSection}
                />

                <Route
                    exact
                    path={`/:code`}
                    component={PersonDataSection}
                />



                <Route
                    exact
                    path={`/`}
                    component={inicio}
                />

            </Switch>
        </Router>
    )
}

export default PrestapolisPublic;