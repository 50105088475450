/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author danielCastano
 * @file Unauthorized/index.js
 * @description Unauthorized container
 */

/** Dependencies */
import React, { useEffect, useState } from "react";

import logoprestapolis2 from "../img/logoprestapolis2.png";
import Sad from "../img/Sad.svg";
import "./styles.css";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { Button } from "@material-ui/core";
import { useHistory, useParams, useLocation } from "react-router-dom";


const NotRegistered = ({nombres}) => {

  const goToHome = () => {
  
    setTimeout(() => {
      window.location.replace("https://www.prestapolis.com/");
    });
  };

  const nombre = nombres.split(" ", 1);

  return( 
        <div className="marginReject">
        <div className="logoSad">
          <div className="logoCentralSad">
          <img src={Sad} />{" "}
          </div>
        </div>
        <div className="textInicio">
          <br></br>
          <strong>Lo sentimos {nombre}, tu
          solicitud de crédito
          no ha sido aprobada</strong>
        </div>
        <br></br>
        <div className="textContenido">
          <p>Vemos que <strong>aún no podemos aprobar tú solicitud de crédito.</strong> Recuerda que cuando revisamos tu historial crediticio, tenemos en cuenta cosas como:</p>
        </div>
        <div className="clausulas">
          <p className="textClausulas">
            <Brightness1Icon className={"punto"} style={{marginTop: "15pt"}}/>          
            El manejo que le hayas dado a tus creditos.
          </p>
          <p className="textClausulas">
            <Brightness1Icon className={"punto"}/>          
            Información en centrales de riesgos; esto no quiere decir que tengas un reporte negativo.
          </p>
          <p className="textClausulas">
            <Brightness1Icon className={"punto"}/>          
            Actualmente tus obligaciones vigentes reducen tu capacidad de endeudamiento.
          </p>
          <p className="textClausulas">
            <Brightness1Icon className={"punto"}/>          
            Tienes moras vigentes en otras obligaciones. 
          </p>
          <br></br>
        </div>
        <div className="textContenido">
            <strong>Si no es tu caso, debes consultar en los portales de centrales de riesgo o indicar por nuestro{" "}
              <a
              href="https://api.whatsapp.com/send?phone=57%203116952930"
              rel="noopener noreferrer"
              target="_blank"
              className="linksWhatsapp"
              >
              whatsapp
              </a> de servicio al cliente, que ya tienes un paz y salvo de alguna obligación que tengas en mora.</strong>
        </div>
        <div className="textInicio" style={{marginBottom: "20pt"}}>
          <br></br>
          <strong>¡Te esperamos de vuelta en Prestápolis!</strong>
        </div>
        <br></br>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="btn-atras"
          style={{ marginBottom: "20pt" }}
          onClick={(e) => goToHome()}
        >
          <strong>
            Finalizar
          </strong>
        </Button>
        </div>
  )};

export default NotRegistered;