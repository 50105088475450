import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import PrestapolisPublic from './prestapolis';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import theme from './app/config/themaConfig';
import { FAVICON } from "./prestapolis/public/component/view/solicitud/created";

function App() {
  const favicon = localStorage.getItem(FAVICON)

  const faviconUrl = `https://${process.env.REACT_APP_AWS_BUCKET}/${favicon}`;
  
  return (
    <ThemeProvider theme={theme}>
      <PrestapolisPublic />
      <Helmet>
        <link rel="icon" type="image/png" href={faviconUrl} sizes="16x16" />
      </Helmet>
      {/* Resto del contenido */}
    </ThemeProvider>
  );
}

export default App;
