import { request } from "../../../app/api-client/ApiClientRequest";

/**
 * Metodo para consultar algo
 * @param {String} document 
 */
export function getExample(document) {

	let urlServices = `/v1/2222222/${document}`;
	return request({
		url: process.env.REACT_APP_API_PRESTAPOLIS_URL + urlServices,
		method: "GET",

	});
}

export function sendRequest(Request) {
	return request(
		{
			url: `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v1/quotas/quotaRequest`,
			method: "POST",
			body: JSON.stringify(Request),
		},
		"0",
		true
	);
}

export function crearSolicitud(Request) {
	return request(
		{
			url: `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v2/quotas/crearSolicitud`,
			method: "POST",
			body: JSON.stringify(Request),
		},
		"0",
		true
	);
}

export function getInfoByToken(code) {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v1/quotas/gettoken/${code}`;
	return request({
		url: url,
		method: "GET",
	});
}

export function getPlanById(id) {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v1/quotas/planById/${id}`;
	return request({
		url: url,
		method: "GET",
	});
}

export function generateOpt(id) {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v1/quotas/generateOpt/${id}`;
	return request({
		url: url,
		method: "GET",
	});
}

export function generateOptCall(id) {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v2/quotas/generateOptCall/${id}`;
	return request({
		url: url,
		method: "GET",
	});
}

export function sendUserValidate(Request){
	return request(
		{
			url: `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v1/userclient/validate`,
			method: "POST",
			body: JSON.stringify(Request),
		}
	);
}

export function sendPagareAprovate(Request){
	return request(
		{
			url: `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v1/userclient/aprovate`,
			method: "POST",
			body: JSON.stringify(Request),
		}
	);
}

export function getCommerceByName(commerce) {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v1/commerces/${commerce}`;
	return request({
		url: url,
		method: "GET",
	});
}

export function getSucursalByName(sucursal) {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v1/branch/search/apikey/${sucursal}`;
	return request({
		url: url,
		method: "GET",
	});
}

export function getParameters(page, size, filter) {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v1/param?page=${page}&size=${size}&filter=${filter}`;
	return request({
		url: url,
		method: "GET",
	});
}

export function generatePLansNewComerce(Request, idInteres) {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v3/quotas/calculatePlansV4/${idInteres}`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	});
}

export function generatePLans(Request) {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v3/quotas/calculatePlans`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	});
}

export function generateReject(data) {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v2/quotas/newPreSolicitud`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(data),
	});
}

export function generateRejectV2(data) {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v2/quotas/newPreSolicitudV2`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(data),
	});
}

export function getValidate(document,date) {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v1/persondata/validateDocument/${document}/${date}`;
	return request({
		url: url,
		method: "GET",
	});
}

export function getProductsByCommerceId(id, filter) {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v1/catalogo/${id}?filter=${filter}`;
	return request({
		url: url,
		method: "GET",
	});
}
export function getZonesList() {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v1/locations/states`;
	return request({
		url: url,
		method: "GET",
	});
}

export function getCitiesList(idZone) {
	let url = `${process.env.REACT_APP_API_PRESTAPOLIS_URL}/v1/locations/cities/${idZone}`;
	return request({
		url: url,
		method: "GET",
	});
}
