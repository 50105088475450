import React, { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import "../solicitud/created/CreatedStyles.css";
import logo_blanco from "../solicitud/img/logo_blanco.png";
import { HelpValidate } from "../../../domain/help/HelpValidate";
//import { Number, Currency } from "react-number-format"

//material Form
import { makeStyles, useTheme } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { TextField, Modal, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import Alert from "@material-ui/lab/Alert";
import { Typography } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";

//Boton calcula
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import {
  generatePLans,
  getParameters,
  getCommerceByName,
} from "../../../infractructura/ApiPrestapolisPublic";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    display: "flex",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  btnCalcula: {
    "& > *": {
      margin: theme.spacing(1),
      height: 30,
      width: 800,
      fontSize: 10,
      fontWeight: "bold",
    },
    paddingTop: 15,
  },
  buttonProgress: {
    color: "primary",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 290,
  },

  margin: {
    margin: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(1),
  },
  centerv: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
  },
  borderRadius: {
    borderRadius: "3%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  grid: {
    marginLeft: "5px",
    width: "200px",
  },
}));

const ITEM_HEIGHT = 20;
const ITEM_PADDING_TOP = 50;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export default function Calculadora({}) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    register,
    setError,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [isContinuo, setIsContinuo] = useState(true);
  const [estruCuotas, setEstrucuotas] = useState({});

  //let costo = param.filter((param) => param.key === "COSTO_PLATAFORMA");
  const [estados, setEstados] = useState({
    visible: false,
    showLoad: false,
    filter: "",
    title: "",
    message:
      "Debes calcular y seleccionar un plan de pago acorde a tus preferencias",
    type: "",
    catalogo: [0],
  });

  const [button, setbutton] = useState(false);
  const [showLoad, setshowLoad] = useState(false);
  const [monto, setMonto] = useState(false);

  const [plans, setPlans] = useState([]);
  const [intemSelct, setintemSelct] = useState({
    plans: [],
    loanTerm: 0,
    costoPlataforma: 0,
    interes: 0,
    selected: false,
  });

  const [selectCatalogo, setCatalogo] = useState([]);
  const [lParam, setLParam] = useState([]);
  const [commerceData, setCommerceData] = useState({});
  const [MAX, setMAX] = useState(0);
  const [MIN, setMIN] = useState(0);
  const [Estado, setEstado] = useState({
    message: "",
    error: false,
  });

  let costo = lParam.filter((lParam) => lParam.key === "COSTO_PLATAFORMA");

  useEffect(async () => {
    await loadParameters();
    await loadData("prestapolis");
  }, []);

  const loadParameters = () => {
    getParameters(0, 50, "")
      .then((response) => {
        if (response.code === "0000") {
          setLParam(response.content);
        } else {
          setEstado({ message: response.mensaje });
          setEstado({ error: true });
        }
      })
      .catch((error) => {
        setEstado({ showLoad: false });
        setEstado({ error: true });
     
      });
  };

  const loadData = (code) => {
    
    setEstado({ showLoad: true });
    getCommerceByName(code)
      .then((response) => {
        let info = response.respuesta;
     
        if (response.codigoRespuesta === "0000") {
          setEstado({ showLoad: false });
          setCommerceData(info);
          setMAX(info.montoMaximo);
          setMIN(info.montoMinimo);
        } else {
          setEstado({ showLoad: false });
          setEstado({ error: true });
          setEstado({ message: response.mensaje });
        }
      })
      .catch((error) => {
        setEstado({ showLoad: false });
        setEstado({ error: true });
       
      });
  };

  const calculePlan = (data) => {
    let options = [];
    setshowLoad(true);
    let valor = data.valueRequiredAmount;
    if (typeof valor === "string") {
      valor = valor.includes(".") ? valor.split(".") : valor.split(",");
      valor = valor.join("");
      valor = parseInt(valor, 10);
    }
    let abono = data.abono;
    if (typeof abono === "string") {
      abono = abono.includes(".") ? abono.split(".") : abono.split(",");
      abono = abono.join("");
      abono = parseInt(abono, 10);
    }

    let esCuotas = commerceData.estructuraCuotas;
    if (typeof esCuotas === "string") {
      esCuotas = esCuotas.includes('"')
        ? esCuotas.split("")
        : esCuotas.split("");
      esCuotas = esCuotas.join("");
      esCuotas = JSON.parse(esCuotas);
    }

    setEstrucuotas(esCuotas);

    let request = {
      valor: valor,
      abono: abono,
      opciones: esCuotas,
      costoPlataforma: costo,
    };



    if (valor < commerceData.montoMinimo || valor > commerceData.montoMaximo) {
      setshowLoad(false);
      setMonto(true);
    } else {
      setMonto(false);
      generatePLans(request)
        .then((response) => {
         
          if (response.codigoRespuesta === "0000") {
            setbutton(true);
            setshowLoad(false);
            
            response.respuesta.length > 0 &&
              response.respuesta.forEach((option) => {
                options.push({
                  ...option,
                  selected: "",
                });
              });
            //setEstados({ plans: options });
            setPlans(options);
          } else {
            
            setEstados({ visible: true });
          }
          setshowLoad(false);
        })
        .catch((err) => {
          setshowLoad(false);
          
        });
    }

  };

  const selectPlan = (item, key) => {
    let options = [];
    plans.forEach((option) => {
      options.push({
        ...option,
        selected: option.valor === item.valor ? "selected" : "disabled",
      });

      setIsContinuo(false);
    });

    let esCuotas = estruCuotas;

    setPlans(options);
    setintemSelct({
      plans: options,
      loanTerm: item.meses,
      costoPlataforma: esCuotas[key].costoPlataforma,
      interes: item.interes,
      selected: true,
    });
  };

  const onSubmit = (data) => {
  
    calculePlan(data);
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat(["ban", "id"]).format(num);
  };

  return (
    <>
      <div className="index">
        <div className="container-text">
          <div className="logo">
            <div>
              <img alt="Logo" id="logo-prestapolis" src={logo_blanco} />{" "}
              
            </div>
            {false && (
              <div className="Loading justify-content-center">
                <ReactLoading
                  type={"cylon"}
                  color={"#000"}
                  height={50}
                  width={50}
                />
              </div>
            )}
          </div>

          <div className="col">
            <div className="index-simulator">
              <div className="confirm">
                <section className="section">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                      container
                      spacing={1}
                      alignItems="flex-end"
                      className="input-person"
                      id="input-person"
                    >
                      <Grid item>
                        <AssignmentIndIcon color="primary" />
                      </Grid>
                      <Grid item md={true} sm={true} xs={true}>
                        <TextField
                          placeholder="Valor del producto"
                          label="Valor del producto"
                          fullWidth
                          name="valueRequiredAmount"
                          type="tel"
                          //defaultValue={request.valueRequiredAmount}
                          {...HelpValidate().formatNumber(
                            register("valueRequiredAmount", {
                              required: "El valor es requerido.",
                            })
                          )}
                          error={!!errors.valueRequiredAmount}
                          helperText={errors.valueRequiredAmount?.message}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={1}
                      alignItems="flex-end"
                      alignItems="flex-end"
                      className="input-person"
                      id="input-person"
                    >
                      <Grid item>
                        <AssignmentIndIcon color="primary" />
                      </Grid>

                      <Grid item md={true} sm={true} xs={true}>
                        <TextField
                          placeholder="Abono Inicial"
                          label="Abono Inicial"
                          fullWidth
                          name="abono"
                          type="tel"
                          //defaultValue={request.valueRequiredAmount}
                          {...HelpValidate().formatNumber(
                            register("abono", {
                              required: "El valor es requerido.",
                            })
                          )}
                          error={!!errors.abono}
                          helperText={errors.abono?.message}
                        />
                      </Grid>
                    </Grid>

                    <div className={classes.wrapper}>
                      <Grid
                        container
                        justify="center"
                        style={{ marginTop: "20px" }}
                      >
                        <Button
                          color="primary"
                          type="submit"
                          aria-label="add to shopping cart"
                          variant="contained"
                          style={{ textTransform: "none", width: "inherit" }}
                          //startIcon={<AddBoxIcon />}
                        >
                          Calcular
                        </Button>
                      </Grid>
                    </div>

                    {monto ? (
                      <label id="alert-comerce">{`Monto maximo $${formatNumber(
                        Math.floor(MAX)
                      )}, Monto minimo $${formatNumber(
                        Math.floor(MIN)
                      )}`}</label>
                    ) : (
                      <div></div>
                    )}

                    {showLoad === true ? (
                      <div className="Loading justify-content-center">
                        <ReactLoading
                          type={"spokes"}
                          color={"#000"}
                          height={50}
                          width={50}
                          className="loading-creat"
                        />
                      </div>
                    ) : button === true ? (
                      <div id="list-select-plan" className="row">
                        <b style={{ fontSize: "11pt" }}>
                          Selecciona un plan de pago acorde a tus preferencias
                        </b>
                        {plans.map((item, key) => (
                          <div
                            className="btn col-11 form-group my-auto p-0 select-plan"
                            key={key}
                            onClick={(e) => {
                              selectPlan(item, key);
                            }}
                          >
                            <div
                              className={`form-field-Prestapolis-${item.selected} form-control `}
                            >
                              <b className="text-planes">
                                $
                                {`${formatNumber(Math.floor(item.valor))} por ${
                                  item.meses - 1
                                } meses`}
                              </b>

                              <p className="text-p">
                                <b>
                                  {`Interes ${item.interes.interesmensual}%  mes vencido*`}
                                </b>
                                <br></br>
                                <b>
                                  Costo Plataforma: $
                                  {formatNumber(Math.floor(item.costo))}
                                </b>
                                <br></br>
                                <b className="text-b">
                                  <b>
                                    Total a pagar $
                                    {formatNumber(Math.floor(item.pagoTotal))}
                                  </b>
                                </b>
                                <br></br>
                              </p>
                            </div>
                          </div>
                        ))}
                        <p id="terminos-p">
                          * El beneficio del 0% interés aplica mientras se
                          cumpla con los pagos dentro de las fechas
                          establecidas. Ver términos y condiciones.
                        </p>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </form>
                </section>
              </div>
            </div>
          </div>

          <div className="col-12 footer">
            <p>
              <strong>Evite ser victima de fraudes financieros</strong>
              &copy;2021 PrestaPolis S.A.S. todos los derechos reservados{" "}
              {`${process.env.REACT_APP_VERSION}`}{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
