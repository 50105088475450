import React from "react";
import fondo from "../../../img/PRESTA_SEP_3.jpg"
import Grid from '@mui/material/Grid'
import "../../../prestapolis.css"
import "../view/solicitud/created/CreatedStyles.css"
import { height } from "@mui/system";




export  default function inicio  (){

    return(
        <>
        <div className={process.env.REACT_APP_BOX_PRESTAPOLIS} style={{height: 1187}}>
        <Grid container spacing={2} columns={16} className="inicio-body" align ="center">
            <Grid item xs={8}>
                <img src={fondo}/>
            </Grid>
            <Grid item xs={8}>
  
            </Grid>
        </Grid>
        </div>
        </>
    )

}