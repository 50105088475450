/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author danielCastano
 * @file Unauthorized/index.js
 * @description Unauthorized container
 */

/** Dependencies */
import React, { useEffect, useState, createRef } from "react";

import logoprestapolis2 from "../img/logoprestapolis2.png";
import Sad from "../img/Sad.svg";
import "./styles.css";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { Button, Grid, TextField, Modal } from "@material-ui/core";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Slider from '@material-ui/core/Slider';

import {
	generatePLans,
	getProductsByCommerceId,
  generatePLansNewComerce
} from "../../../../infractructura/ApiPrestapolisPublic";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import LogoPrestapolis from "../img/logoPrestapolisSolo.png";



const NewOptions = ({
  handleNext,
	handlePrev,
	commerceData,
	handleEconomicData,
	request, 
	setisProductoIn,
	setbuttonIn,
	planes,
	setPlanes,
	isNewPro,
	planSelect,
	setplanSelect,
	disContinuo,
	setdisContinuo,

  setNewCuota,
  setNewValor,
  newValor,
  codBandera,
  newCuota,

  stateCard,
  setStateCard,
  stateCard2,
  setStateCard2,
  valorCapacidadMinima,
  valorCapacidadMedia,
  valorMonto,
  valorinicial,
  nombres,
  montoMaximo,
  montoMinimo,
}) => {

  const nombre = nombres.split(" ", 1);

  const formatNumber = (num) => {
		return new Intl.NumberFormat(["ban", "id"]).format(num);
	};

  const [plansNegotiation, setPlansNegotiation] = useState([]);
  const [plans, setPlans] = useState([]);
	const [planSliderSelected, setPlanSliderSelected] = useState(null);
  const [prodComerce, setprodComerce] = useState([]);
  const [isContinuo, setIsContinuo] = useState(true);
  const [estruCuotas2, setEstrucuotas2] = useState({});
	const [selectCatalogo, setCatalogo] = useState([]);
	const [button, setbutton] = useState(false)
	const [showLoad, setshowLoad] = useState(false)
  const [valorAbonoMinimo2, setValorAbonoMinimo2] = useState("");
  const [valorProductoAft2, setvalorProductoAft2] = useState("")
  const [valorProducto2, setvalorProducto2] = useState("0");
  const [estruCuotas, setEstrucuotas] = useState({});
  const [catalogoState, setcatalogoState] = useState(false);

  const [continuarFinal, setCotinuarFinal] = useState(false);

  const [openModal, setOpenModal]= useState(true);

  const [intemSelct, setintemSelct] = useState({
		plans: [],
		loanTerm: 0,
		costoPlataforma: 0,
		interes: 0,
		selected: false,
	});

	const [estados, setEstados] = useState({
		visible: false,
		showLoad: false,
		filter: "",
		title: "",
		message:
			"Debes calcular y seleccionar un plan de pago acorde a tus preferencias",
		type: "",
		catalogo: [0],
	});

  const [calculaData, setcalculaData] = useState({
		textproducto: "",
		valueRequiredAmount: "",
		abonoInicial: "",
	});

	const [valorProduc, setValorProduc] = useState(null);
	const [valorProduc2, setValoProduc2] = useState(null);
	const [valorAbono , setValorAbono] = useState(null);
	const [valorAbono2 , setValorAbono2] = useState(null);

  const [valorProducOp2, setValorProducOp2] = useState(null);
	const [valorProduc2Op2, setValoProduc2Op2] = useState(null);
  const [valorAbonoOp2 , setValorAbonoOp2] = useState(null);
	const [valorAbono2Op2 , setValorAbono2Op2] = useState(null);


	const [calcular, setCalcular] =  useState(null);
	const[newComercio, setNewComercio]= useState(commerceData.porcentaje_vsf == 0 ? true:false);

  const [activarCalculo, setActivarCalculo] =  useState(null);

	const [respuesta, setRespuesta] = useState(null);

  const [valorProOp1, setValorProOp1] = useState("");
  const [valorCuotaOp1, setValorCuotaOp1] = useState("");
  const [valorProOp1String, setValorProOp1String]=useState("");
  const [valorCuotaOp1String, setValorCuotaOp1String] = useState("");

  const [valorProOp2, setValorProOp2] = useState("");
  const [valorCuotaOp2, setValorCuotaOp2] = useState("");
  const [valorProOp2String, setValorProOp2String]=useState("");
  const [valorCuotaOp2String, setValorCuotaOp2String] = useState("");

  let esCuotas = estruCuotas;
  const refvalue2 = createRef();
  const [valor2, setValor2] = useState(null);

  const closeModal = (e) =>{
    setOpenModal(false);
  }

  const handleChange2 = (e) => {
    setValor2(formatNumber((e.target.value).toString().replaceAll(".","").replaceAll(",","")));
  };

  const refvalue3 = createRef();
  const [valor3, setValor3] = useState(null);

  const handleChange3 = (e) => {
    setValor3(formatNumber((e.target.value).toString().replaceAll(".","").replaceAll(",","")));
  };

  const refvalue4 = createRef();
  const [valor4, setValor4] = useState(null);

  const handleChange4 = (e) => {
    setValor4(formatNumber((e.target.value).toString().replaceAll(".","").replaceAll(",","")));
  };

  const refvalue5 = createRef();
  const [valor5, setValor5] = useState(null);

  const handleChange5 = (e) => {
    setValor5(formatNumber((e.target.value).toString().replaceAll(".","").replaceAll(",","")));
  };

  const loadCatalogo = (filter) => {
		
		const id = commerceData.id;
		let catalogo = [];
		setEstados({ showLoad: true });
		getProductsByCommerceId(id, filter.toLowerCase())
			.then((response) => {
				if (response.codigoRespuesta === "0000") {
					response.respuesta.map((item) =>
						catalogo.push({
							...item,
							estructuraCuotas: JSON.parse(item.estructuraCuotas),
						})
					);
					setprodComerce(catalogo);
					setcatalogoState(true);

				} else {
					
				}
			})
			.catch((err) => {
				setEstados({ visible: true });
				
			});
		setEstados({ showLoad: false });
	};

  const filter = (filter) => {
		setEstados({ filter: filter });
		if (estados.catalogo = [0]) {
			loadCatalogo("");
		}
	};

  useEffect(() => {
    setRespuesta(true);
    if(valorMonto > montoMaximo){
      setValorProOp1(montoMaximo);
    }else{
      setValorProOp1(valorMonto);
    }
    setValorProOp1String((formatNumber((valorProOp1).toString().replaceAll(".","").replaceAll(",",""))))
    if(stateCard === "selected" && codBandera === "003"){
      if(valorMonto-valorCapacidadMinima < 20000){
        setValorCuotaOp1(20000)
      }else{
        setValorCuotaOp1(valorMonto - valorCapacidadMinima)
      }
      setValorCuotaOp1String((formatNumber((valorCuotaOp1).toString().replaceAll(".","").replaceAll(",",""))))
    }
    if(stateCard === "selected" && codBandera === "004"){
      if(valorMonto-valorCapacidadMedia < 20000){
        setValorCuotaOp1(20000)
      }else{
        setValorCuotaOp1(valorMonto - valorCapacidadMedia)
      }
      setValorCuotaOp1String((formatNumber((valorCuotaOp1).toString().replaceAll(".","").replaceAll(",",""))))
    }

    if(valorinicial === 0){
      setValorCuotaOp2(20000);
    }else{
      setValorCuotaOp2(valorinicial);
    }

    setValorCuotaOp2String((formatNumber((valorCuotaOp2).toString().replaceAll(".","").replaceAll(",",""))))
    if(stateCard2 === "selected" && codBandera === "003"){
      setValorProOp2(valorinicial + valorCapacidadMinima)
      setValorProOp2String((formatNumber((valorProOp2).toString().replaceAll(".","").replaceAll(",",""))))
    }
    if(stateCard2 === "selected" && codBandera === "004"){
      setValorProOp2(valorinicial + valorCapacidadMedia)
      setValorProOp2String((formatNumber((valorProOp2).toString().replaceAll(".","").replaceAll(",",""))))
    }
		
  }, [setValorProOp1, setValorProOp1String, valorMonto, valorProOp1, setValorCuotaOp2, valorinicial, valorCapacidadMinima, setRespuesta,
    valorCapacidadMedia, valorCuotaOp1, setValorCuotaOp2String, valorCuotaOp2, stateCard2, setValorProOp2, setValorProOp2String, valorProOp2]);

	useEffect(() => {
		if(valor2 != null ){
		setValorProduc((valor2).replaceAll(".","").replaceAll(",",""));
    setValoProduc2(parseInt(valorProduc));
    if(codBandera === "003"){
      if((parseInt(valorProduc) - valorCapacidadMinima) < 20000){
        setValorAbono(20000);
      }else{
        setValorAbono(parseInt(valorProduc) - valorCapacidadMinima);
      }
    }
    if(codBandera === "004"){
      if((parseInt(valorProduc) - valorCapacidadMedia) < 20000){
        setValorAbono(20000);
      }else{
        setValorAbono(parseInt(valorProduc) - valorCapacidadMedia);
      }
    }
    setRespuesta(true);
		}
		if(valorAbono != null){
			setValorAbono2((formatNumber((valorAbono).toString().replaceAll(".","").replaceAll(",",""))))
		};

    if(valor5 != null ){
      setValorProducOp2((valor5).replaceAll(".","").replaceAll(",",""));
      setValoProduc2Op2(parseInt(valorProducOp2));
      if(codBandera === "003"){
        setValorAbonoOp2(parseInt(valorProducOp2) + valorCapacidadMinima);
      }
      if(codBandera === "004"){
        setValorAbonoOp2(parseInt(valorProducOp2) + valorCapacidadMedia);
      }
      setRespuesta(true);
      }
      if(valorAbonoOp2 != null && valorAbonoOp2 > 20000){
        setValorAbono2Op2((formatNumber((valorAbonoOp2).toString().replaceAll(".","").replaceAll(",",""))))
      };
  }, [ valor2, setValorProduc, setValoProduc2, valorProduc, setValorAbono, valorCapacidadMinima, valorCapacidadMedia, setRespuesta, 
    valorAbono, setValorAbono2, valor5, setValorProducOp2, setValoProduc2Op2, valorProducOp2, codBandera, setValorAbono2Op2, valorAbonoOp2]);

	useEffect(async () => {
		
		filter("");
		
		setintemSelct(planSelect);
		setvalorProducto2(isNewPro);
		setIsContinuo(disContinuo);
		setValorAbonoMinimo2(request.minAbono);
		setvalorProductoAft2(request.valueRequiredAmount);

		if(plans.length === 0 && planes.length > 0){
			planes.map((item)=>
				plans.push({
					...item
				})
			);
		}

		if(Object.entries(estruCuotas).length === 0){

			let esCuotas = commerceData.estructuraCuotas;
			if (typeof esCuotas === "string") {
				esCuotas = esCuotas.includes('"') ? esCuotas.split("") : esCuotas.split("");
				esCuotas = esCuotas.join("");
				esCuotas = JSON.parse(esCuotas)
	
			}
			setEstrucuotas(esCuotas);
		}

		if(calculaData.valueRequiredAmount === ""){

			setcalculaData({
				textproducto:  catalogoState? request.descripcion : request.selectProducto,
				valueRequiredAmount: request.valueRequiredAmount,
				abonoInicial: 0,
			});
		}

		if(selectCatalogo.length === 0){
			let listPcatalogo = request.selectProducto;
			setCatalogo(listPcatalogo);
		}
	}, [])

  const selectPlan = (item, key) => {
		let options = [];
		plansNegotiation.forEach((option) => {
			options.push({
				...option,
				selected: option.valor === item.valor ? "selected" : "disabled"
			});

      setNewCuota(null);
      setNewValor(null);
      setCalcular(true);
			setIsContinuo(false);
			setdisContinuo(false);
		});

		let esCuotas = estruCuotas2;

    setCotinuarFinal(true);
		setPlansNegotiation(options);
		setintemSelct({
			plans: options,
			loanTerm: item.meses,
			costoPlataforma: esCuotas[key].costoPlataforma,
			interes: item.interes,
			commission: esCuotas[key].comision,
			selected: true,
		})

		setplanSelect({
			plans: options,
			loanTerm: item.meses,
			costoPlataforma: esCuotas[key].costoPlataforma,
			interes: item.interes,
			commission: esCuotas[key].comision,
			selected: true,
		})
	};

  const handleCard = (e) => {
    setStateCard("selected");
    setStateCard2("disabled");
    setActivarCalculo(false);
    setCotinuarFinal(false);
  }

  const handleCard2 = (e) => {
    setStateCard("disabled");
    setStateCard2("selected");
    setActivarCalculo(false);
    setCotinuarFinal(false);
  }

	const calculePlan = (data) =>{

		let catalogoSelect = selectCatalogo[0] === undefined || selectCatalogo[0] === null?
			[]:selectCatalogo[0];
		
		let esCuotasProducto =  catalogoSelect.estructuraCuotas;

		let isMultiPro = selectCatalogo.length > 1? true:false;

		let esCuotas =	
				isMultiPro === true ? commerceData.estructuraCuotas:
				esCuotasProducto === null || esCuotasProducto === undefined? 
				commerceData.estructuraCuotas: esCuotasProducto;

		if (typeof esCuotas === "string") {
			esCuotas = esCuotas.includes('"') ? esCuotas.split("") : esCuotas.split("");
			esCuotas = esCuotas.join("");
			esCuotas = JSON.parse(esCuotas)
		}

		setEstrucuotas2(esCuotas);

		let request = {
			valor: stateCard === "selected" ? valorProduc2 || valorProOp1 : valorAbonoOp2 || valorProOp2,
			abono: stateCard === "selected" ? valorAbono || valorCuotaOp1 : valorProduc2Op2 || valorCuotaOp2,
			opciones: esCuotas,
      porcentaje_vsf:commerceData.porcentaje_vsf,
      seguro:0
		};

    
    if(respuesta=== true){
    const optionsNegotiation = [];
    if(commerceData.apikey == process.env.REACT_APP_KEY_COMERCE){
      generatePLansNewComerce(request, process.env.REACT_APP_ID_INTERES)

        .then((response) => {
          if (response.codigoRespuesta === "0000") {
            setbutton(true);
            setshowLoad(false);
            
            response.respuesta.length > 0 &&
              response.respuesta.forEach((option) => {
                optionsNegotiation.push({
                  ...option,
                  selected: "",
                });
              });
            //setEstados({ plans: options });
            setPlansNegotiation(optionsNegotiation);
            setPlanSliderSelected(5);
          } else {
          
            setEstados({ visible: true });
          }
          setshowLoad(false);
        })
        .catch((err) => {
          setshowLoad(false);
        
        });
    }else{
    generatePLans(request)
					.then((response) => {
						if (response.codigoRespuesta === "0000") {
							setbutton(true);
							setshowLoad(false);
							
							response.respuesta.length > 0 &&
								response.respuesta.forEach((option) => {
									optionsNegotiation.push({
										...option,
										selected: "",
									});
								});
							//setEstados({ plans: options });
							setPlansNegotiation(optionsNegotiation);
							setPlanSliderSelected(5);
						} else {
						
							setEstados({ visible: true });
						}
						setshowLoad(false);
					})
					.catch((err) => {
						setshowLoad(false);
					
					});}
        }
			}
  

	const handleCalculo = (data) => {
   
		calculePlan(data);
		setActivarCalculo(false);
  }
  
  const nextStep = (e) => {

    if (calcular === true){
		const data = {

			valueRequiredAmount: stateCard === "selected" ? valorProduc2 || valorProOp1 : valorAbonoOp2 || valorProOp2,
			descripcion: calculaData.textproducto,
			loanTerm: intemSelct.loanTerm,
			costoPlataforma: intemSelct.costoPlataforma,
			abonoInicial: stateCard === "selected" ? valorAbono || valorCuotaOp1 : valorProduc2Op2 || valorCuotaOp2,
			plans: intemSelct.plans,
			selected: intemSelct.selected,
			interes: intemSelct.interes,
			minimo: commerceData.montoMinimo,
			maximo: commerceData.montoMaximo,
			catalogo: plans,
			producto: calculaData.textproducto,
			minAbono: valorAbonoMinimo2,

		}

		handleEconomicData(data);
		setisProductoIn(true);
		setbuttonIn(true);
		setPlanes(plans);
    handleCard();
    handleCard2();
		handleNext();
    }
	}

  return( 
        <div className="marginReject">
        { 
          <Modal
          open={openModal}
          onClose={closeModal}
          >    
          <div className="modalDocumentValidation">
            <div>
              <img
                className="logoNegociacion"
                alt="Logo"
                id="industriaycomerio"
                src={LogoPrestapolis}
              />
              <div className="textModalAprovate" style={{marginTop: "15pt", fontSize: "12pt", textAlign: "justify"}}>
                  <br></br>
                  <div style={{color:"#707070", fontSize: "1.4rem"}}>
                  Hola {<span style={{fontWeight: "bold", color: "black"}}>{nombre}</span>}, hemos validado tu perfil y te queremos sugerir las siguientes 2 opciones de financiación para que puedas llevarte ese producto que tanto deseas. 
                    </div>
                  </div>
                  <br></br>
              </div>    
            <div style={{textAlign: "center"}}>
            <Button 
            variant="contained"
            color="primary"
            className="MuiTypography-colorPrimary"
            style={{ textTransform: "none", width: "14rem", borderRadius: "100px" }}
            onClick={(e) => {
              closeModal();
            }}
            >Negociación</Button>
            </div>
          </div> 
        </Modal>
        }
        <div style={{fontSize: "1rem"}}>
          <br></br>
          <strong>A continuación te planteamos 2 opciones de financiación, para que escojas la que más de acomode a tus preferencias</strong>
        </div>
        <br></br>
        <div style={{display: "flex", marginBottom: "-36pt"}}>
        <CardActionArea onClick={() => handleCard()}>
          <div id="list-select-plan" className="row">
            <div className="select-plan">
              <div className={`form-field-Prestapolis-${stateCard} form-control `}>
                <CardContent>
                <div className="textInicio2">
                  <strong>Opción 1</strong>
                </div>
                </CardContent>
              </div>
            </div>
          </div>
        </CardActionArea>

        <div style={{width:"20%"}}></div>
        
        <CardActionArea onClick={() => handleCard2()}>
          <div id="list-select-plan" className="row">
            <div className="select-plan">
              <div className={`form-field-Prestapolis-${stateCard2} form-control `}>
                <CardContent>
                <div className="textInicio2">
                  <strong>Opción 2</strong>
                </div>
                </CardContent>
              </div>
            </div>
          </div>
        </CardActionArea>
        </div>
        <br></br>
        
        {stateCard === "selected" ?
          <div id="list-select-plan" className="row">
        <div className="select-plan">
        <div className={`form-field-Prestapolis-disabled form-control `}>

        <div style={{fontSize: "1rem", marginTop: "10pt"}}>
          Calcula el valor de la cuota inicial con el valor del producto que deseas.
        </div>
        <br></br>
        
        <Grid container spacing={1} alignItems="flex-end" className="input-person" id="input-select" >
          <Grid  item md={true} sm={true} xs={true} >
            <TextField
              required
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                className: "label__color",
                inputComponent: formatNumber,
              }}
              placeholder="800.000"
              label="Valor del producto"
              fullWidth
              name="valueRequiredAmount"
              type="tel"
              id="input-select"
              color="primary"
              mask={"000.000.000"}
              inputRef={refvalue2}
              value={valor2 || valorProOp1String}
              error={(valorProduc2 || valorProOp1)  > montoMaximo  ||  (valorProduc2 || valorProOp1) < montoMinimo || valorProduc2 === 0 }
							helperText={(valorProduc2 || valorProOp1)  > montoMaximo ? `El valor del producto no puede ser mayor que ${montoMaximo}`:null
              || (valorProduc2 || valorProOp1 ) < montoMinimo || valorProduc2 === 0 ? `El valor del producto no puede ser menor que ${montoMinimo}`:null}	
              onChange={handleChange2}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="flex-end" className="input-person" id="input-select" >
          <Grid  item md={true} sm={true} xs={true} >
            <TextField
              required
              disabled={stateCard === "selected"}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                className: "label__color",
                inputComponent: formatNumber,
              }}
              placeholder="240.000"
              label="Valor del Abono"
              fullWidth
              name="valueRequiredAmount"
              type="tel"
              id="input-select"
              color="primary"
              mask={"000.000.000"}
              inputRef={refvalue3}
              value={valor3 || valorAbono2 || valorCuotaOp1String}
              error={(valorAbono || valorCuotaOp1)  < 20000}
							//helperText={(valorAbono || valorCuotaOp1)  < 20000 ? "En este momento el valor de la cuota inicial esta dando menos de 80.000 pesos suba el valor del producto":null}	
              onChange={handleChange3}
            />
          </Grid>
        </Grid>

        <Grid container justify="center">
          <Button
            color="primary"
            type="submit"
            aria-label="add to shopping cart"
            variant="contained"
            style={{ textTransform: "none", width: "14rem", borderRadius: "100px", padding: "1pt", }}
						onClick={handleCalculo}
          >
            <strong>
            Calcular
            </strong>
          </Button>
      </Grid>
      </div>
      </div>
      </div> : null}

      {stateCard2 === "selected" ?
      <div id="list-select-plan" className="row">
      <div className="select-plan">
      <div className={`form-field-Prestapolis-disabled form-control `}>

        <div style={{fontSize: "1rem", marginTop: "10pt"}}>
          Calcula el valor del producto que puedes adquirir con la cuota inicial que dispones.
        </div>
        <br></br>

        <Grid container spacing={1} alignItems="flex-end" className="input-person" id="input-select" >
          <Grid  item md={true} sm={true} xs={true} >
            <TextField
              required
              disabled={stateCard2 === "selected"}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                className: "label__color",
                inputComponent: formatNumber,
              }}
              placeholder="800.000"
              label="Valor del producto"
              fullWidth
              name="valueRequiredAmount"
              type="tel"
              id="input-select"
              color="primary"
              mask={"000.000.000"}
              inputRef={refvalue4}
              value={valor4 || valorAbono2Op2 || valorProOp2String}
              error={(valorAbonoOp2 || valorProOp2)  > montoMaximo }
							helperText={(valorAbonoOp2 || valorProOp2)  > montoMaximo ? `El valor del producto no puede ser mayor que ${montoMaximo}`:null}
              onChange={handleChange4}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="flex-end" className="input-person" id="input-select" >
          <Grid  item md={true} sm={true} xs={true} >
            <TextField
              required
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                className: "label__color",
                inputComponent: formatNumber,
              }}
              placeholder="240.000"
              label="Valor del Abono"
              fullWidth
              name="valueRequiredAmount"
              type="tel"
              id="input-select"
              color="primary"
              mask={"000.000.000"}
              inputRef={refvalue5}
              value={valor5 || valorCuotaOp2String}
              error={(valorProduc2Op2 || valorCuotaOp2)  < 20000  ||  valorProduc2Op2 === 0}
							helperText={((valorProduc2Op2 || valorCuotaOp2)  < 20000 ||  valorProduc2Op2 === 0) ? "El valor minimo de la cuota inicial no puede ser menor a 80.000":null}	
              onChange={handleChange5}
            />
          </Grid>
        </Grid>

        <Grid container justify="center">
          <Button
            color="primary"
            type="submit"
            aria-label="add to shopping cart"
            variant="contained"
            style={{ textTransform: "none", width: "14rem", borderRadius: "100px", padding: "1pt", }}
            onClick={handleCalculo}
          >
            <strong>
            Calcular
            </strong>
          </Button>
      </Grid>
      </div>
      </div>
      </div>: null}

      {activarCalculo === false?
      <div id="list-select-plan" className="row">
      <p id="discrete-slider-custom" style={{marginTop: "10pt", maxWidth: "8rem", fontSize:"18px"}} gutterBottom>
        <strong>
        Plazo - meses
        </strong>
      </p>
			{plansNegotiation.length > 0  && <Slider 
				getAriaValueText={value => `${value}`}
				aria-labelledby="discrete-slider-custom"
				value={planSelect.loanTerm ? planSelect.loanTerm : planSliderSelected}
				step={null}
				marks={ plansNegotiation.map(({ meses }) => ({ value: meses, label: `${meses-1}` }))}
				max={ plansNegotiation[plansNegotiation.length - 1].meses}
				min={plansNegotiation[0].meses}
				onChange={(_event, value) => {
					const key = plansNegotiation.findIndex(({ meses }) => meses === value);
					const item = plansNegotiation[key];
					setPlanSliderSelected(value);
					selectPlan(item, key);
				}}
			/>}
      <p id="discrete-slider-custom" style={{marginTop: "10pt", maxWidth: "22rem", fontSize:"18px"}} gutterBottom>
        <strong>
        Esta seria tu nueva cuota a pagar
        </strong>
      </p>

      {plansNegotiation.length > 0 && plansNegotiation.filter(({ meses }) => {
        if (planSelect.loanTerm){
          return meses === planSelect.loanTerm
        }
        return meses === planSliderSelected
       
      })
      .map((item, key) => {
        const { meses, costo } = item;
        return (<div
          className="select-plan"
          key={`${key}-${meses}-${costo}`}
          onClick={(e) => {

            selectPlan(item, key);
            
          }}
          
        >
          {calcular === true   ? (
          <div className={`form-field-Prestapolis-${item.selected} form-control `}>
            <div style={{display:"flex", alignItems: "center", justifyContent:"space-evenly"}}>
              <div style={{marginTop: "5pt"}}>
                <b className="text-planes" style={{ fontSize: '28px' }}>
                  ${`${formatNumber(Math.floor(item.valor))}`} 
                </b>
              </div>
              <div className={`select-plan2 ${item.selected}`} style={{display:"flex", alignItems: "center", marginTop: "5px"}}>
                <div>
                  <CalendarTodayIcon  style={{ fontSize: '1.7rem', marginTop: "2px"}}/>
                </div>
                <div style={{ marginBottom: "2pt"}} >
                  <b className="text-planes" style={{ fontSize: '1rem' }}>
                    {`${valorAbono != "0"?item.meses-1:item.meses} meses`}
                  </b>
                </div>
                
              </div>
            </div>
            <Typography className={`linea ${item.selected}`} component="div"/>
            <p className="text-p">
              <Brightness1Icon className={`punto ${item.selected}`}/>
              <b style={{ marginRight: "5em", marginBottom: "1em" }}>
              {`Interes ${((commerceData.apikey == process.env.REACT_APP_KEY_COMERCE ? item.interes.interesmensual.toFixed(2) : item.interes.interesmensual.toFixed(3)))}% mes vencido*`}              </b>
              <br></br>
              <br></br>
              <Brightness1Icon className={`punto disabled`}/>
              <b style={{ marginRight: "4.2em" }}>
               {` Costo administración: $${formatNumber(Math.floor(item.costo/item.meses))} por cuota`}
              </b>
            <br></br>
            <br></br>
            <Brightness1Icon className={`punto ${item.selected}`}/>
                <b style={{ marginRight: "2.0em" }}>
                {`Fianza FGA +IVA: $${formatNumber(Math.floor( (item.vsf)/(item.meses -1)))} por cuota`}
                </b>
              <br></br>
              <br></br>
              <Brightness1Icon className={`punto ${item.selected}`}/>
                <b style={{ marginRight: "14.0em" }}>
                Seguro: ${formatNumber(Math.floor(item.seguro == null ? "0" : item.seguro))}
                </b>
              <br></br>
              <br></br>
              <b className="text-b">
                <b style={{ marginRight: "1em", fontSize: "1.4em", fontWeight: "bold" }}>
                  Total a pagar ${formatNumber(Math.floor(item.pagoTotal))}
                </b>
              </b>
              <br></br>
            </p>
          </div>
          ):
          (
          <div className={`form-field-Prestapolis-disabled form-control `}>
          <div style={{display:"flex", alignItems: "center", justifyContent:"space-evenly"}}>
            <div style={{marginTop: "5pt"}}>
              <b className="text-planes" style={{ fontSize: '28px' }}>
                ${`${formatNumber(Math.floor(item.valor))}`} 
              </b>
            </div>
            <div className={`select-plan2 disabled`} style={{display:"flex", alignItems: "center", marginTop: "5px"}}>
              <div>
                <CalendarTodayIcon  style={{ fontSize: '1.7rem', marginTop: "2px"}}/>
              </div>
              <div style={{ marginBottom: "2pt"}} >
                <b className="text-planes" style={{ fontSize: '1rem' }}>
                  {`${valorAbono != "0"?item.meses-1:item.meses} meses`}
                </b>
              </div>
              
            </div>
          </div>
          <Typography className={`linea disabled`} component="div"/>
          <p className="text-p">
            <Brightness1Icon className={`punto disabled`}/>
            <b style={{ marginRight: "5em", marginBottom: "1em" }}>
            {`Interes ${((commerceData.apikey == process.env.REACT_APP_KEY_COMERCE ? item.interes.interesmensual.toFixed(2) : item.interes.interesmensual.toFixed(3)))}% mes vencido*`}
            </b>
            <br></br>
            <br></br>
            <Brightness1Icon className={`punto disabled`}/>
              <b style={{ marginRight: "4.2em" }}>
               {` Costo administración: $${formatNumber(Math.floor(item.costo/item.meses))} por cuota`}
              </b>
            <br></br>
            <br></br>
            <Brightness1Icon className={`punto ${item.selected}`}/>
                <b style={{ marginRight: "2.0em" }}>
                {`Fianza FGA +IVA: $${formatNumber(Math.floor( (item.vsf)/(item.meses -1)))} por cuota`}
                </b>
              <br></br>
              <br></br>
              <Brightness1Icon className={`punto ${item.selected}`}/>
                <b style={{ marginRight: "14.0em" }}>
                Seguro: ${formatNumber(Math.floor(item.seguro == null ? "0" : item.seguro))}
                </b>
              <br></br>
              <br></br>
            <b className="text-b">
              <b style={{ marginRight: "1em", fontSize: "1.4em", fontWeight: "bold" }}>
                Total a pagar ${formatNumber(Math.floor(item.pagoTotal))}
              </b>
            </b>
            <br></br>
          </p>
        </div>)}

        </div>)
        }
      )}
      </div>: null}

      <div >
        <Grid container justify="center" style={{ marginTop: '50px',  }}>
          <Button
            disabled={continuarFinal === false}
            variant="contained"
            color="secondary"
            onClick={(e) => nextStep()}
            style={{ textTransform: "none", width: "14rem", borderRadius: "100px" }}>
            <strong>
            Continuar
            </strong>
          </Button>

        </Grid>
        </div>


      <br></br>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="btn-atras"
        style={{ marginBottom: "20pt" }}
        onClick={(e) => handlePrev()}
      >
        <strong>
          Volver
        </strong>
      </Button>
      </div>

  )};

export default NewOptions;
