import React, { useEffect, useState } from "react";
import "../created/CreatedStyles.css"
import {TextField, Modal, Button} from "@material-ui/core";



 const ModalButton = ({isOpen, openModal, message, seconMessage, eventButton}) => (

	<div className="modalDiv">					
	<Modal
		open={isOpen}
		onClose={openModal}					
		>
			<div className="modal">
				<div>
					<h3 style={{textAlign: "center"}}>{message}</h3>
					<h6>{seconMessage}</h6>
				</div>
				<Button 
				variant="contained"
				color="primary"
				className = "button-modal"
				style={{ textTransform: "none" }}
				onClick={(e) => eventButton()}
				>Aceptar</Button>
			</div>
	</Modal>
	</div>



);

export default ModalButton;



