import React, { useEffect, useState } from "react";

import Sad from "../img/logoPrestapolisSolo.png";
import "./styles.css";
import { Button } from "@material-ui/core";



const GenericMessage = ({nombres , messageOne, messageTow, data}) => {

  const goToHome = () => {
    
    setTimeout(() => {
      window.location.replace("https://www.prestapolis.com/");
    });
  };

  const nombre = nombres.split(" ", 1);
  
  return( 
        <div className="marginReject">
        <div className="logoSad">
          <div className="logoCentralSad">
          <img src={`https://${process.env.REACT_APP_AWS_BUCKET}/${data.comercioBean?.logo}`} />{" "}
          </div>
        </div>
        <div className="textInicio">
          <br></br>
          <strong>Hola {nombre}</strong>
        </div>
        <br></br>
        <div className="textContenido">
          <p>{messageOne}, {messageTow}</p>
        </div>
        <div className="textInicio" style={{marginBottom: "20pt"}}>
          <br></br>
          <strong>{data.comercioBean?.nombre}</strong>
        </div>
        <br></br>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="btn-atras"
          style={{ marginBottom: "20pt" }}
          onClick={(e) => goToHome()}
        >
          <strong>
            Finalizar
          </strong>
        </Button>
        </div>
  )};

export default GenericMessage;