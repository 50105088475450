import React, { useEffect, useState, createRef } from "react";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import "./CreatedStyles.css";
import ModalButtonPage from "../modal/ModalOneButton";

//material Form
import { debounce, makeStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { TextField, Modal, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import Alert from "@material-ui/lab/Alert";
import { Typography } from "@material-ui/core";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import RoomIcon from "@material-ui/icons/Room";
import { HelpValidate } from "../../../../domain/help/HelpValidate";
import MenuItem from "@material-ui/core/MenuItem";
import logoFrontal from "../img/doc_frontal.svg";
import logoPosterior from "../img/doc_posterior.svg";
import logoFrontalValidate from "../img/doc_frontal_val.svg";
import logoPosteriorValidate from "../img/doc_posterios_val.svg";
import picture from "../img/picture.svg"
import picture1 from "../img/picture-1.svg"


import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

//API
import {
  getZonesList,
  getCitiesList,
} from "../../../../infractructura/ApiPrestapolisPublic";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  modal: {
    position: "relative",
    width: 300,
    borderRadius: 10,
    backgroundColor: "white",
    border: "2px soild #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    left: "8%",
    transform: "translate(-50%, - 50%)",
  },
}));

//Retorna la informacion capturada del formulario
export default function ContactDataSection({
  handlePrev,
  idNumber,
  sendData,
  datosAdd,
  contacts,
  data,
  idSolicitud,
  persona,
  contact,

  nombres,
  primerApellido,
  segundoApellido,
  fechaNacimineto,
  celular,
  correo,

  primerCredito,
  morasVigentes,
  setValor,
  valor,

  datoContacto,
  setDatoContacto,
  detalle,
  setKey,
  documento,

  lsZona,
  setlsZona,

  // variables para controlar expecion
  isOpenModal,
  messageModal,
  secondMessage,
  type,
  setisOpenModal,
  setIsContinuo,
  isContinuo,
  disabledErr

}) {


  const classes = useStyles();
  const {
    register,
    setError,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const formatNumber = (num) => {
    return new Intl.NumberFormat(["ban", "id"]).format(num);
  };

  const refvalue = createRef();
  const cellPhone = createRef();
  const handleChange2 = (e) => {
    setValor(formatNumber((e.target.value).toString().replaceAll(".", "").replaceAll(",", "")));

  };
  const handleChangeContact = (e) => {
    setDatoContacto(e.target.value);
  };



  // const [isContinuo, setIsContinuo] = useState(false);
  const [isErrorContinue, setIsErrorContinue] = useState(false);
  const [citiList, setcitiList] = useState([]);
  const [idDepartamento, setidDepartamento] = useState(0);

  const [showLoad, setshowLoad] = useState(false);
  const [showLoad2, setshowLoad2] = useState(false);
  const [showLoad3, setshowLoad3] = useState(false);

  const [preubaValor, setPreubaValor] = useState(false);
  const [datoContactoAdd, setDatoContactoAdd] = useState(false);

  const [messageFrontSide, setMessageFront] = useState("");
  const [messageBackSide, setMessageBack] = useState("");
  const [messageSelfie, setMessageSelfie] = useState("");

  const [loading, setLoading] = useState(false);
  const [successfulUploadFrontSide, setSuccesFull] = useState(false);
  const [message, setMessage] = useState("");
  // const [isOpenModal, setisOpenModal] = useState(false);
  const [reqDireccion, setreqDireccion] = useState(true);
  const [nomenclature, setNomenclature] = useState(true);
  const [dir1, setDir1] = useState(true);
  const [dir2, setDir2] = useState(true);
  const [labelFront, setlabelFront] = useState("Lado frontal ");
  const [labelBack, setlabelBack] = useState("Lado posterior ");
  const [labelSelfie, setlabelSelfie] = useState("Foto selfie ");
  const [errorFile, seterrorFile] = useState(false);

  const handleChange = (event) => {
    setidDepartamento(event.target.value);
  };

  const cambioColorFront = (e) => {
    e.target.classList.add("color-front");
  };

  const handleChangeNomn = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    if (name === "nomenclature") {
      value != "" ? setreqDireccion(false) : setreqDireccion(true);
      setNomenclature(false);
    } else if (name === "addressNumber1") {
      value != "" ? setreqDireccion(false) : setreqDireccion(true);
      setDir1(false);
    } else if (name === "addressNumber2") {
      value != "" ? setreqDireccion(false) : setreqDireccion(true);
      setDir2(false);
    } else if (name === "indications") {
      if (value != "") {
        setNomenclature(false);
        setDir1(false);
        setDir2(false);
        setreqDireccion(true);
      }
    }
  };

  useEffect(async () => {
    await getZonesList()
      .then((response) => {

        if (response.respuesta) {
          setlsZona(response.respuesta);
        } else {

        }
      })
      .catch((err) => {

      });
    if (contacts.zone && contacts.zone !== "")
      await getCitiesList(Number(contacts.zone))
        .then((response) => {
          if (response.respuesta) {
            if (parseInt(idDepartamento, 10) === 11) {
              const citi = {
                city: [
                  {
                    id: "0001",
                    nombre: "BOGOTA, D.C",
                  },
                ],
              };
              setcitiList(citi.city);
            }
            setcitiList(response.respuesta);
          } else {

          }
        })
        .catch((err) => {

        });
  }, [idDepartamento]);

  useEffect(async () => {
    if (lsZona !== "" && idDepartamento != 0) {
      await getCitiesList(parseInt(idDepartamento, 10))
        .then((response) => {
          if (response.respuesta) {
            setcitiList(response.respuesta);
            if (parseInt(idDepartamento, 10) === 11) {
              const citi = {
                city: [
                  {
                    id: "0001",
                    nombre: "BOGOTA, D.C",
                  },
                ],
              };
              setcitiList(citi.city);
            }
          } else {

          }
        })
        .catch((err) => {

        });
    }

  }, [idDepartamento]);

  const handlePrueba = (event) => {
    if (valor === null) {
      setPreubaValor(true);
    }
  };

  const onFileUpload = async (event, type, loading, message, filename) => {

    type = type === null || type === undefined ? "" : type;

    if (type === "frontSideDocument") {
      setshowLoad(true);
    }
    if (type === "backSideDocument") {
      setshowLoad2(true);
    }
    if (type === "fotoSelfie") {
      setshowLoad3(true);
    }

    const file = event.target.files[0];

    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    ) {
      const formData = new FormData();
      let doc = idNumber;
      
      let nitComercio = data.comercioBean?.nit != undefined ? data.comercioBean?.nit : data.nit
      
      if (typeof doc === "string") {
        doc = doc.includes(".") ? doc.split(".") : doc.split(",");
        doc = doc.join("");
        doc = parseInt(doc, 10);
      }
      //this.setState({ [loading]: true });
      setLoading(true);
      // const name = file.name.replace(' ', '-');
      let ramdom = Math.floor(Math.round(Math.random() * (999 - 100) + 100)); //Ramdom 3 dijistos
      const lastDot =
        file.name === undefined ? (file.name = "") : file.name.lastIndexOf(".");
      const ext = file.name.substring(lastDot + 1);
      formData.append("file", file, doc + "_" + filename + "_" + ramdom + "." + ext);

      let msg = "";
      if (file.size < 10485760 && doc !== "") {
        let url = data.baas != false ? `${process.env.REACT_APP_FILES}?doc=Comercios/${nitComercio}/usuarios/${doc}` : `${process.env.REACT_APP_FILES}?doc=Usuario/${doc}`;

        await fetch(url, {
          // content-type header should not be specified!
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {

            if (response.respuesta) {
              //this.setState({ [loading]: false, [type]: response.mensaje });
              if (type === "frontSideDocument") {
                setSuccesFull(true);
                setshowLoad(false);
                setlabelFront(filename);
                setMessageFront(response.mensaje);
              }
              if (type === "backSideDocument") {
                setSuccesFull(true);
                setshowLoad2(false);
                setlabelBack(filename);
                setMessageBack(response.mensaje);
              }
              if (type === "fotoSelfie") {
                setSuccesFull(true);
                setshowLoad3(false);
                setlabelSelfie(filename);
                setMessageSelfie(response.mensaje);
              }
              else {

              }
            } else {
              if (type === "frontSideDocument") {
                setSuccesFull(true);
              }
              if (type === "backSideDocument") {
                setSuccesFull(true);
              }
              if (type === "fotoSelfie") {
                setSuccesFull(true);
              }
              else {

              }

              setMessage(response.mensaje);
              setLoading(false);
            }
          })
          .catch((error) => {

            msg = "Error";
            setSuccesFull(true);
          });
      } else {
        if (type === "frontSideDocument") {
          setSuccesFull(false);
        }
        if (type === "backSideDocument") {
          setSuccesFull(false);
        }
        if (type === "fotoSelfie") {
          setSuccesFull(false);
        }
        else {

        }
        event.target.classList.add("is-invalid");
        event.target.classList.remove("is-valid");
        msg =
          doc === ""
            ? "Debe diligenciar el documento primero"
            : "Tamaño no permitido maximo 10 MB, Unicamente archivos .pdf, .png, .jpg, .jpeg";
        setSuccesFull(true);
      }
    } else {
      if (type === "frontSideDocument") {
        setshowLoad(false);
        event.target.value = null;
        seterrorFile(true);
        setTimeout(() => {
          seterrorFile(false);
        }, 3000);
      }
      if (type === "backSideDocument") {
        setshowLoad2(false);
        event.target.value = null;
        seterrorFile(true);
        setTimeout(() => {
          seterrorFile(false);
        }, 3000);
      }
      if (type === "fotoSelfie") {
        setshowLoad3(false);
        event.target.value = null;
        seterrorFile(true);
        setTimeout(() => {
          seterrorFile(false);
        }, 3000);
      }
    }
  };

  const onSubmit = (data, type) => {


    if (messageFrontSide === "" || messageBackSide === "" || messageSelfie === "") {
      sendData(data, messageFrontSide, messageBackSide, messageSelfie, true);
    } if (messageFrontSide != "" && messageBackSide != "" && messageSelfie != "") {
      sendData({
        ...data, id: idSolicitud,
        persona,
        contact,
        nombres,
        primerApellido,
        segundoApellido,
        fechaNacimineto,
        celular,
        correo,
        primerCredito,
        morasVigentes,
      }, messageFrontSide, messageBackSide, messageSelfie, false);
      // setisOpenModal(!isOpenModal);
    }
  };

  const backStep = () => {
    handlePrev();

    // sendData(data, messageFrontSide, messageBackSide);
  };

  const goToHome = () => {

    let urlPrincipal ="https://www.prestapolis.com/";
    if(data.baas == true && data.configuracionesBaas.length > 0){
      for (let index = 0; index < data.configuracionesBaas.length; index++) {
        const element = data.configuracionesBaas[index];
        if(element.key == 'URL_PRINCIPAL'){
          urlPrincipal = element.value
        }
      }
    }
      setTimeout(() => {
        window.location.replace(urlPrincipal);
      });

  };

  const openModal = () => {
    setisOpenModal(!isOpenModal);
  };

  return !datosAdd ? null : (
    <>
      <div className="confirm">
        <ModalButtonPage
          isOpen={isOpenModal}
          openModal={type != "succes" ? openModal : goToHome}
          message={messageModal}
          eventButton={type != "succes" ? openModal : goToHome}
          seconMessage={secondMessage}
        />

        <section className="section">
          <form
            onSubmit={handleSubmit(onSubmit)}
            name="form"
            autocomplete="off"
          >
            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              className="input-person"
            >
              <Grid item md={true} sm={true} xs={true}>
                <FormControl id="form-select" align="left">
                  <InputLabel style={{
                    color: '#FFFFFF',
                    background: '#2BAEB3'
                  }}
                    id="input-select"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      className: "label__color",
                    }} shrink >
                    Ocupación*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    name="occupation"
                    required
                    fullWidth
                    notched
                    id="input-select"
                    autocomplete="off"
                    variant="outlined"
                    type="tel"

                    defaultValue={datosAdd.occupation}
                    {...register("occupation", {
                      required: "La cedula es requerida es requerido.",
                      pattern: {
                        minLength: 3,
                        message: "La cedula debe tener minimo 10 caracteres",
                      },
                    })}
                    error={!!errors.occupation}
                    helperText={errors.occupation?.message}
                  >
                    <MenuItem value="">
                      <em>Seleccione ocupación</em>

                    </MenuItem>
                    <MenuItem value="Empleado">Empleado</MenuItem>
                    <MenuItem value="Independiente">Independiente</MenuItem>
                    <MenuItem value="Rentista">Rentista</MenuItem>
                    <MenuItem value="Pensionado">Pensionado</MenuItem>
                    <MenuItem value="Estudiante">Estudiante</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              className="input-person"
              id="input-select"
            >
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  labelId="demo-simple-select-label"
                  required
                  placeholder="$800.000"
                  label="Ingresos mensuales"
                  fullWidth
                  autocomplete="off"
                  id="input-select"
                  type="tel"
                  name="monthlyIncomeRange"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    className: "label__color",
                  }}
                  inputProps={{ minLength: 3 }}
                  defaultValue={datosAdd.monthlyIncomeRange}
                  {...HelpValidate().formatNumber(register('monthlyIncomeRange', {
                    required: "Es requerido poner los ingresos mensuales.",
                  }))}
                  error={!!errors.monthlyIncomeRange}
                  helperText={errors.monthlyIncomeRange?.message}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} alignItems="flex-end" className="input-person" id="input-select" >
              <Grid item md={true} sm={true} xs={true} >
                <TextField
                  required
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    className: "label__color",
                    inputComponent: formatNumber,
                  }}
                  placeholder="$400.000"
                  label="Gastos mensuales"
                  fullWidth
                  name="valueRequiredAmount"
                  id="input-select"
                  color="primary"
                  type="tel"
                  inputProps={{ minLength: 3 }}
                  mask={"000.000.000"}
                  inputRef={refvalue}
                  value={valor}
                  onChange={handleChange2}
                  error={valor === "0" || preubaValor === true && valor === null}
                  helperText={valor === "0" || preubaValor === true && valor === null ? "Los gastos mensuales no pueden ser 0" : null}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              className="input-person"
            >
              <Grid item md={true} sm={true} xs={true}>
                <FormControl id="form-select" align="left">
                  <InputLabel
                    id="input-select"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      className: "label__color",
                    }} shrink >
                    Departamento
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="input-select"
                    name="departamento"
                    autocomplete="off"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    defaultValue={contacts.zone}
                    {...register("departamento", {
                      required: "La cedula es requerida es requerido.",
                      pattern: {
                        minLength: 3,
                        message: "La cedula debe tener minimo 10 caracteres",
                      },
                    })}
                    error={idDepartamento === 0 ? !!errors.departamento : null}
                    helperText={errors.departamento?.message}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>Seleccione departamento</em>
                    </MenuItem>
                    {lsZona.map((item) => {
                      const { nombre, id } = item;
                      return (
                        <MenuItem
                          key={`${id}-${nombre}`}
                          value={id || nombre}
                        >
                          {nombre}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              className="input-person"
            >
              <Grid item md={true} sm={true} xs={true}>
                <FormControl id="form-select" align="left">
                  <InputLabel
                    id="input-select"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      className: "label__color",
                    }} shrink >
                    Municipio
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="input-select"
                    name="municipio"
                    align="Left"
                    autocomplete="off"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    defaultValue={contacts.city}
                    {...register("municipio", {
                      required: "Requerido",
                      minLength: 3,
                    })}
                    error={!!errors.municipio}
                    helperText={errors.municipio?.message}
                  >
                    <MenuItem value="">
                      <em>Seleccione municipio</em>
                    </MenuItem>

                    {citiList.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.nombre || contacts.city}
                      >
                        {item.nombre || contacts.city}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                alignItems="flex-end"
                className="input-person"
            >
              <Grid item md={true} sm={true} xs={true} style={{ display: "flex" }}>
                <FormControl id="form-select-dir" align="left">

                  <Grid item md={true} sm={true} xs={true}>
                    <InputLabel
                        id="input-select"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                          className: "label__color",
                        }} shrink >
                      Dirección
                    </InputLabel>
                    <Select
                        id="input-select"
                        labelId="demo-simple-select-label"
                        align="Left"
                        autocomplete="off"
                        name="nomenclature"
                        label="Direccion"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        defaultValue={contacts.nomenclature}
                        //defaultValue={contacts.address}
                        {...register("nomenclature", {
                        })}
                        error={nomenclature === true ? !!errors.nomenclature : null}
                        helperText={errors.nomenclature?.message}
                        onChange={handleChangeNomn}
                    >
                      <MenuItem value="">
                        <em>Direccion</em>
                      </MenuItem>
                      <MenuItem value="Calle">Calle</MenuItem>
                      <MenuItem value="Carrera">Carrera</MenuItem>
                      <MenuItem value="Transversal">Transversal</MenuItem>
                      <MenuItem value="Diagonal">Diagonal</MenuItem>
                      <MenuItem value="Avenida">Avenida</MenuItem>
                      <MenuItem value="Circular">Circular</MenuItem>
                      <MenuItem value="Autopista">Autopista</MenuItem>
                      <MenuItem value="Kilometro">Kilometro</MenuItem>
                      <MenuItem value="Avenida Calle">Avenida Calle</MenuItem>
                      <MenuItem value="Otro">Otro</MenuItem>
                    </Select>
                  </Grid>
                </FormControl>
                <Grid item style={{ padding: "2pt" }}></Grid>
                <Grid xs={2} >
                  <TextField
                      style={{ width: "100%" }}
                      placeholder="1c"
                      label="Dir."
                      fullWidth
                      autocomplete="off"
                      name="addressNumber1"
                      type="text"
                      variant="outlined"
                      color="primary"
                      defaultValue={contacts.addressNumber1}
                      InputLabelProps={{
                        shrink: true,
                        className: "label__color",
                      }}
                      //defaultValue={contacts.address}
                      {...register("addressNumber1", {
                      })}
                      error={dir1 === true ? !!errors.addressNumber1 : null}
                      helperText={errors.addressNumber1?.message}
                      onChange={handleChangeNomn}
                  />
                </Grid>
                <Grid item style={{ padding: "4pt" }}></Grid>
                <Grid xs={2} >
                  <TextField
                      style={{ width: "100%" }}
                      placeholder="15-16"
                      label="#"
                      fullWidth
                      autocomplete="off"
                      name="addressNumber2"
                      variant="outlined"
                      color="primary"
                      InputLabelProps={{
                        shrink: true,
                        className: "label__color",
                      }}
                      defaultValue={contacts.addressNumber2}
                      //defaultValue={contacts.address}
                      type="text"
                      {...register("addressNumber2", {
                      })}
                      error={dir2 === true ? !!errors.addressNumber2 : null}
                      helperText={errors.addressNumber2?.message}
                      onChange={handleChangeNomn}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/*<Grid*/}
            {/*    container*/}
            {/*    spacing={1}*/}
            {/*    alignItems="flex-end"*/}
            {/*    className="input-person"*/}
            {/*>*/}
            {/*  <Grid item md={true} sm={true} xs={true} x>*/}
            {/*    <TextField*/}
            {/*        placeholder="Indicaciones adicionales"*/}
            {/*        label="Indicaciones adicionales"*/}
            {/*        fullWidth*/}
            {/*        autocomplete="off"*/}
            {/*        id="input-select"*/}
            {/*        name="indications"*/}
            {/*        type="text"*/}
            {/*        variant="outlined"*/}
            {/*        InputLabelProps={{*/}
            {/*          shrink: true,*/}
            {/*          className: "label__color",*/}
            {/*        }}*/}
            {/*        defaultValue={contacts.indications}*/}
            {/*        {...register("indications", {*/}
            {/*          pattern: {*/}
            {/*            minLength: 1,*/}
            {/*            message: "el nombre ebe tener minimo 3",*/}
            {/*          },*/}
            {/*        })}*/}
            {/*        error={!!errors.indications}*/}
            {/*        helperText={errors.indications?.message}*/}
            {/*        onChange={handleChangeNomn}*/}
            {/*    />*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}



            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              className="input-person"
            >
              <Grid item md={true} sm={true} xs={true}>
                <FormControl id="form-select" align="left">
                  <InputLabel
                    id="input-select"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      className: "label__color",
                    }} shrink >
                    Padrino/Madrina
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    name="padrino"
                    required
                    fullWidth
                    notched
                    id="input-select"
                    autocomplete="off"
                    variant="outlined"
                    type="tel"

                    defaultValue={datosAdd.padrino}
                    {...register("padrino", {
                      required: "El campo es requerido.",
                      pattern: {
                        minLength: 3,
                      },
                    })}
                    error={!!errors.padrino}
                    helperText={errors.padrino?.message}
                  >
                    <MenuItem value="">
                      <em>Seleccione ocupación</em>

                    </MenuItem>
                    <MenuItem value="Madre">Madre</MenuItem>
                    <MenuItem value="Padre">Padre</MenuItem>
                    <MenuItem value="Hermano/a">Hermano/a</MenuItem>
                    <MenuItem value="Conyuge">Cónyuge</MenuItem>
                    <MenuItem value="Familiar">Familiar</MenuItem>
                    <MenuItem value="Amigo">Amigo</MenuItem>
                    <MenuItem value="Otro">Otro</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              className="input-person"
            >
              <Grid item md={true} sm={true} xs={true} x>
                <TextField
                  labelId="demo-simple-select-label"
                  required
                  placeholder="Nombre Completo Referencia"
                  label="Nombre Completo Referencia"
                  fullWidth
                  autocomplete="off"
                  id="input-select"
                  name="nombreCompleto"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    className: "label__color",
                  }}
                  inputProps={{ minLength: 3 }}
                  defaultValue={datosAdd.detalle}
                  {...HelpValidate().writeText(
                    register('nombreCompleto', {
                      required: "Es requerido poner los ingresos mensuales.",
                    }))}
                  error={!!errors.detalle}
                  helperText={errors.detalle?.message}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              className="input-person"
            >
              <Grid item md={true} sm={true} xs={true} x>
                <TextField
                  placeholder="Celular Referencia"
                  label="Celular Referencia"
                  fullWidth
                  color="primary"
                  id="input-select"
                  name="datoContacto"
                  type="tel"
                  autocomplete="off"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    className: "label__color",
                  }}
                  defaultValue={datosAdd.datoContacto}
                  {...HelpValidate().vrInicial(
                    register("datoContacto", {
                      required: "La cedula es requerida.",
                    }),
                    10,
                    10,
                    3,
                    setError,
                    clearErrors,
                  )}
                  required={"La cedula es requerida."}
                  error={!!errors.datoContacto}
                  helperText={errors.datoContacto?.message}
                />
              </Grid>
            </Grid>

            <div class="text-white" style={{ textAlign: "left", marginLeft: "auto" }}>
              <p style={{ fontSize: "15px !important" }}>Digitalización de documento de identidad</p>
            </div>

            <div style={{ display: "flex", marginTop: "20px", marginLeft: "auto" }}>
              {messageFrontSide == "" ? (
                <div class="container-input" style={{ marginRight: "30pt" }}>
                  <img
                    className="logoCedulas"
                    alt="Logo"
                    id="logoFrontal"
                    src={logoFrontal}
                  />
                </div>
              ) : (
                <div class="container-input" style={{ marginRight: "30pt" }}>
                  <img
                    className="logoCedulas"
                    alt="Logo"
                    id="logoFrontalValidate"
                    src={logoFrontalValidate}
                  />
                </div>)
              }
              {messageBackSide == "" ? (
                <div class="container-input" style={{ marginRight: "10pt", }}>
                  <img
                    className="logoCedulas"
                    alt="Logo"
                    id="logoPosterior"
                    src={logoPosterior}
                  />
                </div>
              ) : (
                <div class="container-input">
                  <img
                    className="logoCedulas"
                    alt="Logo"
                    id="logoPosteriorValidate"
                    src={logoPosteriorValidate}
                  />
                </div>)
              }
            </div>

            <div style={{ display: "flex", margin: "-15px", marginBottom: "50px" }} >
              <div class="container-input">
                <input
                  type="file"
                  id="frontSideDocument"
                  className="inputfile inputfile-2"
                  accept="image/jpeg,image/png, image/jpg,application/pdf"
                  // defaultValue={datosAdd.frontSideDocument}
                  onChange={(e) => {
                    onFileUpload(
                      e,
                      "frontSideDocument",
                      "showLoadFrontSideFile",
                      "messageFrontSide",
                      "Lado frontal"
                    );
                  }}
                  onClick={cambioColorFront}
                />

                <label for="file-2" style={{ textTransform: "none", borderRadius: "100px", justifyContent: "center" }}>
                  <span class="iborrainputfile"></span>
                  <strong>
                    {labelFront}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="iborrainputfile"
                      width="20"
                      height="17"
                      viewBox="0 0 20 17"
                    >
                      <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                    </svg>

                  </strong>
                </label>
                {showLoad === true ? (
                  <div className="Loading justify-content-center">
                    <ReactLoading
                      type={"cylon"}
                      color={"#000"}
                      height={30}
                      width={30}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>

              <div class="container-input">
                <input
                  type="file"
                  id="backSideDocument"
                  className="inputfile inputfile-2"
                  accept="image/jpeg,image/png, image/jpg,application/pdf"
                  // defaultValue={datosAdd.backSideDocument}
                  onChange={(e) => {
                    onFileUpload(
                      e,
                      "backSideDocument",
                      "showLoadBackSideFile",
                      "messageBackSide",
                      "Lado posterior"
                    );
                  }}
                  onClick={cambioColorFront}
                />

                <label for="file-2" style={{ textTransform: "none", borderRadius: "100px", justifyContent: "center" }}>
                  <span class="iborrainputfile"></span>
                  <strong>
                    {labelBack}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="iborrainputfile"
                      width="20"
                      height="17"
                      viewBox="0 0 20 17"
                    >
                      <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                    </svg>
                  </strong>
                </label>
                {showLoad2 === true ? (
                  <div className="Loading justify-content-center">
                    <ReactLoading
                      type={"cylon"}
                      color={"#000"}
                      height={30}
                      width={30}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div style={{ fontSize: "10pt", marginTop: "-35pt" }} className="textContenidoApproved3">Toma las fotos de tu cédula sobre un superficie plana, evita el uso de flash y/o estar a contraluz, asegura que la foto esté bien enfocada y evita recortar los bordes del documento.</div>
            <br></br>
            {messageSelfie === "" ? (
              <div >
                <img
                  className="logoPicture2"
                  alt="Logo"
                  id="industriaycomerio"
                  src={picture} S
                />
              </div>) :
              (
                <div >
                  <img
                    className="logoPicture2"
                    alt="Logo"
                    id="industriaycomerio"
                    src={picture1}
                  />
                </div>)
            }
            <div style={{ width: "60%" }} class="container-input">
              <input
                type="file"
                id="backSideDocument"
                className="inputfile inputfile-2"
                accept="image/jpeg,image/png, image/jpg,application/pdf"
                // defaultValue={datosAdd.fotoSelfie}
                onChange={(e) => {
                  onFileUpload(
                    e,
                    "fotoSelfie",
                    "showLoadSelfie",
                    "messageSelfie",
                    "Foto Selfie"
                  );
                }}
                onClick={cambioColorFront}
              />

              <label for="file-2" style={{ textTransform: "none", borderRadius: "100px", justifyContent: "center" }}>
                <span class="iborrainputfile"></span>
                <strong>
                  {labelSelfie}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="iborrainputfile"
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                  >
                    <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                  </svg>
                </strong>
              </label>
              {showLoad3 === true ? (
                <div className="Loading justify-content-center">
                  <ReactLoading
                    type={"cylon"}
                    color={"#000"}
                    height={30}
                    width={30}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div style={{ fontSize: "10pt" }} className="textContenidoApproved3">Evita tapar tu rostro con el documento, tus manos, mascarillas u otros objetos</div>

            <div className={classes.wrapper}>
              <Grid container justify="center" style={{ marginTop: "20px" }}>
                <Button
                  disabled={
                    messageFrontSide != "" && messageBackSide != "" && messageSelfie != ""
                      ? false
                      : disabledErr
                  }
                  type="submit"
                  // onMouseEnter={handleSubmit(onSubmit)}
                  variant="contained"
                  id="enviar datos"
                  name="enviar datos"
                  style={{
                    textTransform: "none",
                    width: "14rem",
                    borderRadius: "100px",
                    color: '#FFFFFF',
                    background: '#2BAEB3'
                  }}
                >
                  Enviar datos
                </Button>
              </Grid>
              {isContinuo && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
            <div className={classes.wrapper}>
              <Grid container justify="center" style={{ marginTop: "20px", color: "#0A699E" }}>
                <Button
                  variant="contained"
                  color="primary"
                  id="volver"
                  name="volver"
                  className="btn-atras"
                  type="submit"
                >
                  Volver
                </Button>
              </Grid>

            </div>
          </form>
          <br></br>
          {errorFile && (
            <Alert severity="error">El tipo de archivo no es valido</Alert>
          )}
          <br></br>
        </section>
      </div>
    </>
  );
}
